import React, { useState, useMemo, useCallback } from 'react'
import { initReactI18next } from 'react-i18next'
import PropTypes from 'prop-types'
import i18n from 'i18next'
import HttpApi from 'i18next-http-backend'
import moment from 'moment'
import { getLanguageFromLocalStorage } from './helper'
import loadLocale, { locales } from './locales'

const availableLanguages = [
  {
    value: 'de',
    label: 'DE',
  },
  {
    value: 'en',
    label: 'EN',
  },
]

const defaultLanguageKey = getLanguageFromLocalStorage() || 'en'

export const defaultContextValue = () => ({
  language: locales[defaultLanguageKey] || locales.en,
  setLanguages: () => {},
  availableLanguages,
})

i18n
  .use(HttpApi)
  .use(initReactI18next)
  .init({
    lng: defaultLanguageKey,
    fallbackLng: 'en',
    defaultNS: 'common',
    interpolation: {
      escapeValue: false,
    },
    react: {
      useSuspense: true,
    },
    ns: [
      'common',
      'auth',
      'login',
      'register',
      'contracts',
      'profile',
      'sidebar',
      'table',
      'translation',
      'viewReport',
    ],
  })

const LocaleContext = React.createContext(defaultContextValue())

export const LocaleProvider = ({ children }) => {
  const [language, setStateLanguage] = useState(defaultContextValue().language)
  const setLanguage = useCallback(
    newLang => {
      setStateLanguage(locales[newLang])
      i18n.changeLanguage(locales[newLang]?.name)
    },
    [setStateLanguage]
  )

  const context = useMemo(
    () => ({
      language,
      setLanguage,
      availableLanguages,
    }),
    [language, setLanguage]
  )

  loadLocale(language).then(localeObject => {
    moment.locale(localeObject?.moment)
  })
  return <LocaleContext.Provider value={context}>{children}</LocaleContext.Provider>
}
LocaleProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

export default LocaleContext
