import React, { useState, useEffect } from 'react'
import { NavLink } from 'react-router-dom'
import PropTypes from 'prop-types'
import { useTheme } from '@emotion/react'
import { Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import clsx from 'clsx'
import { ArrowDown } from '../../../assets/icons'

const useStyles = makeStyles(theme => ({
  navContainer: {
    '& > div': {
      textDecoration: 'none',
      color: theme.palette.textGrey,
      cursor: 'pointer',
    },
    '& > div.active': {
      color: theme.palette.secondary.main,
    },
    '& a': {
      textDecoration: 'none',
      color: theme.palette.textGrey,
    },
    '& a.active': {
      color: theme.palette.secondary.main,
    },
  },
  menuItem: {
    fontWeight: 600,
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    justifyContent: 'space-between',
    alignItems: 'center',
    display: 'flex',
  },
  childContainer: {
    paddingLeft: theme.spacing(2),
  },
  arrow: {
    marginLeft: theme.spacing(2),
    marginRight: '5px',
    verticalAlign: 'middle',
    transform: 'translateY(50%)',
  },
  arrowRotated: {
    transform: 'rotate(180deg)',
  },
}))

const MenuItem = ({ item }) => {
  const [open, setOpen] = useState(false)
  const classes = useStyles()
  const { to, subItems, title, onClick, notLinked } = item
  const { location } = window
  const theme = useTheme()
  useEffect(() => {
    if (subItems?.filter(child => child.to.includes(location.pathname)).length) setOpen(true)
    else setOpen(false)
  }, [location.pathname, subItems])
  const renderWithChildren = () => (
    <>
      <NavLink
        exact
        to={to}
        tabIndex={0}
        role="button"
        onKeyDown={() => setOpen(!open)}
        onClick={() => setOpen(!open)}
      >
        <Typography
          color={location.pathname.includes(to) ? 'secondary' : 'inherit'}
          className={classes.menuItem}
        >
          {title}
          <ArrowDown className={clsx(classes.arrow, open ? classes.arrowRotated : undefined)} />
        </Typography>
      </NavLink>
      {open ? (
        <div className={classes.childContainer}>
          {subItems.map(child => (
            <NavLink key={child.to} to={child.to} exact>
              <Typography
                color={
                  window.location.pathname === child.to.split('?')[0]
                    ? 'inherit'
                    : theme.palette.textGrey
                }
                className={classes.menuItem}
              >
                {child.title}
              </Typography>
            </NavLink>
          ))}
        </div>
      ) : null}
    </>
  )

  const renderWithoutChildren = () =>
    onClick ? (
      <div tabIndex={-1} onKeyPress={() => {}} role="button" onClick={onClick}>
        <Typography
          color={location.pathname.includes(to) ? 'secondary' : 'inherit'}
          className={classes.menuItem}
        >
          {title}
        </Typography>
      </div>
    ) : (
      <>
        {notLinked ? (
          <Typography color="secondary" className={classes.menuItem}>
            {title}
          </Typography>
        ) : (
          <NavLink to={to} exact>
            <Typography
              color={location.pathname.includes(to) ? 'secondary' : 'inherit'}
              className={classes.menuItem}
            >
              {title}
            </Typography>
          </NavLink>
        )}
      </>
    )
  return (
    <div className={classes.navContainer}>
      {item?.subItems?.length ? renderWithChildren() : renderWithoutChildren()}
    </div>
  )
}
MenuItem.propTypes = {
  item: PropTypes.shape({
    onClick: PropTypes.func,
    to: PropTypes.string,
    notLinked: PropTypes.bool,
    title: PropTypes.string.isRequired,
    subItems: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string.isRequired,
        to: PropTypes.string.isRequired,
      })
    ),
  }).isRequired,
}

export default MenuItem
