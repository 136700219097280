import React from 'react'
import PropTypes from 'prop-types'
import { ME_QUERY } from '../graphql/queries'
import { useMe, useUser } from '../utils/hooks'
import LoggedInLayout from './LoggedInLayout'
import LoggedOutLayout from './LoggedOutLayout'

const MainLayout = ({ children, ...props }) => {
  useMe(ME_QUERY, 30000)
  const { user } = useUser()
  if (user.id) {
    return <LoggedInLayout {...props}>{children}</LoggedInLayout>
  }
  return <LoggedOutLayout {...props}>{children}</LoggedOutLayout>
}

MainLayout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default MainLayout
