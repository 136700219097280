import { styled } from '@mui/material/styles'

const Divider = styled('hr')(({ theme, noSpacing }) => ({
  borderTop: `1px solid ${theme.palette?.primary?.main}`,
  ...(!!noSpacing && {
    margin: 0,
    padding: 0,
  }),
}))

export default Divider
