import React, { Suspense } from 'react'
import PropTypes from 'prop-types'
import { Grid } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { Loading, Footer } from '../components/atoms'
import { Breadcrumbs, ContactBox } from '../wrappers'
import Header from './Header'

const useStyles = makeStyles(theme => ({
  content: {
    minHeight: '80vh',
    width: '100%',
    maxWidth: theme.containerWidth,
    margin: 'auto',
    padding: theme.spacing(1),
  },
  children: {
    paddingTop: theme.spacing(4),
    height: '100%',
  },
  paddingTop: {
    paddingTop: theme.spacing(4),
  },
}))

const LoggedOutLayout = ({ children }) => {
  const classes = useStyles()
  return (
    <div>
      <Suspense fallback={<Loading />}>
        <Header />
      </Suspense>
      <div className={classes.content}>
        <Suspense fallback={<Loading />}>
          <Breadcrumbs />
        </Suspense>
        <Grid container spacing={4}>
          <Grid item xs={12} lg={8}>
            <Suspense fallback={<Loading />}>
              <div className={classes.children}>{children}</div>
            </Suspense>
          </Grid>
          <Grid item xs={12} lg={4}>
            <Suspense fallback={<Loading />}>
              <div className={classes.paddingTop}>
                <ContactBox orientation="column" />
              </div>
            </Suspense>
          </Grid>
        </Grid>
      </div>
      <Suspense fallback={<Loading />}>
        <Footer />
      </Suspense>
    </div>
  )
}
LoggedOutLayout.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
}

export default LoggedOutLayout
