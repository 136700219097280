import React from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { yupResolver } from '@hookform/resolvers/yup'
import { Grid, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import * as Yup from 'yup'
import { Button, Input } from '../../atoms'

const useStyles = makeStyles(theme => ({
  button: {
    color: theme.palette.common.black,
    padding: '10px 45px',
    borderRadius: '2px',
    fontSize: '0.875rem',
  },
  input: {
    width: '65%',
  },
  label: {
    color: theme.palette.textGrey,
  },
}))

const EmailForm = ({ onSubmit, disabled, title, buttonText }) => {
  const classes = useStyles()
  const { t } = useTranslation('auth')

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email(t('invalidEmail'))
      .required(t('required'))
      .transform(value => value.toLowerCase()),
  })

  const methods = useForm({ resolver: yupResolver(validationSchema) })

  const {
    handleSubmit,
    formState: { errors },
    register,
  } = methods
  return (
    <form onSubmit={!disabled ? handleSubmit(onSubmit) : () => {}}>
      <Grid container spacing={3}>
        <Grid xs={12} item>
          <Typography variant="h2">{title}</Typography>
        </Grid>
        <Grid xs={12} item>
          <Input
            {...register('email')}
            label={t('email')}
            error={errors?.email?.message}
            helperText={errors?.email?.message}
            small
            className={classes.input}
            labelClassName={classes.label}
            required
          />
        </Grid>
        <Grid item>
          <Button type="submit" color="primary" className={classes.button} disabled={disabled}>
            {buttonText}
          </Button>
        </Grid>
        <Grid xs={12} item>
          <Typography variant="body2">{t('mandatoryFields')}</Typography>
        </Grid>
      </Grid>
    </form>
  )
}
EmailForm.propTypes = {
  onSubmit: PropTypes.func,
  disabled: PropTypes.bool,
  title: PropTypes.string,
  buttonText: PropTypes.string,
}
EmailForm.defaultProps = {
  onSubmit: () => {},
  disabled: false,
  title: 'Title',
  buttonText: 'Submit',
}

export default EmailForm
