import React from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import { Breadcrumbs as MBreadcrumbs } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { Home } from '../../../assets/icons'

const useStyles = makeStyles(theme => ({
  link: {
    textDecoration: 'none',
    fontWeight: 600,
    color: theme.palette.textGrey,
    '&:hover': {
      color: theme.palette.secondary.main,
    },
    '& svg': {
      marginRight: theme.spacing(1),
    },
  },
  textGrey: {
    color: theme.palette.textGrey,
  },
  spacingBottom: {
    paddingBottom: theme.spacing(2),
  },
}))

const Breadcrumbs = ({ items }) => {
  const classes = useStyles()
  return (
    <MBreadcrumbs
      className={classes.spacingBottom}
      separator={<NavigateNextIcon className={classes.textGrey} fontSize="small" />}
      aria-label="breadcrumb"
    >
      <Link className={classes.link} to="/">
        <Home />
        HOME
      </Link>
      {items?.map(item =>
        item.link ? (
          <Link key={item.label} to={item.link} className={classes.link}>
            {item.icon}
            {item.label}
          </Link>
        ) : (
          <span key={item.label} className={classes.link}>
            {item.icon}
            {item.label}
          </span>
        )
      )}
    </MBreadcrumbs>
  )
}
Breadcrumbs.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      link: PropTypes.string,
      icon: PropTypes.any,
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
    })
  ),
}
Breadcrumbs.defaultProps = {
  items: [],
}

export default Breadcrumbs
