import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from '@apollo/client'
import { SideBar } from '../components/organisms'
import { CUSTOMERS_SIDEBAR_QUERY, ME_QUERY } from '../graphql/queries'
import CustomerContext from '../utils/CustomerContext'
import { useLogout } from '../utils/hooks'
import { getOrderedSystems, hideDisabledSystems } from '../views/Dashboard/Contract/systems'

const SideBarWithData = () => {
  const { customers, setCustomers, setUser } = useContext(CustomerContext)

  const { t } = useTranslation('sidebar')
  useQuery(CUSTOMERS_SIDEBAR_QUERY, {
    onCompleted: data => {
      setCustomers(data?.customers)
    },
  })
  const logout = useLogout()

  useQuery(ME_QUERY, {
    onCompleted: data => {
      setUser(data.me)
    },
  })

  const generateCustomerEntry = customer => ({
    title: t('contractNrX', { number: customer.agreementNo }),
    to: `/contracts/contract/${customer.agreementNo}`,
    subItems: getOrderedSystems(hideDisabledSystems(customer?.systems))?.map(system => ({
      title: t(system),
      to: `/contracts/contract/${customer.agreementNo}/${system.toLowerCase()}?tab=overview`,
    })),
  })

  const items = customers?.length
    ? [
        {
          title: t('yourContracts'),
          to:
            customers?.length > 0
              ? `/contracts/contract/${customers?.[0]?.agreementNo}`
              : '/profile',
          notLinked: true,
        },
        ...(customers?.map(generateCustomerEntry) || []),
        {
          title: null,
        },
        // {
        //   title: t('viewInvoices'),
        //   to: '/invoices',
        // },
        {
          title: t('manageData'),
          to: '/profile',
        },
        {
          title: t('logout'),
          to: '/logout',
          onClick: logout,
        },
      ]
    : [
        {
          title: t('logout'),
          to: '/logout',
          onClick: logout,
        },
      ]
  return (
    <div>
      <SideBar items={items} />
    </div>
  )
}

export default SideBarWithData
