import React, { useState, useMemo, useCallback } from 'react'
import PropTypes from 'prop-types'
import * as Sentry from '@sentry/browser'
import { getUser, setUser as setStorageUser, removeUser as removeStorageUser } from './helper'

const initialValues = {
  name: 'Max Mustermann',
  first_name: 'Max',
  last_name: 'Mustermann',
  role: '',
}

const getStoreUser = () => {
  const userString = getUser()
  if (userString) {
    const user = { ...JSON?.parse(userString) }
    user.name = [user.first_name, user.last_name].join(' ')
    return user
  }
  return undefined
}

const userContext = {
  user: {
    ...initialValues,
    ...getStoreUser(),
  },
  setUser: () => undefined,
  removeUser: () => undefined,
}

const UserContext = React.createContext(userContext)

export const UserContextProvider = ({ children }) => {
  const [user, setLocalUser] = useState(userContext.user)

  const setUser = useCallback(
    userData => {
      if (userData) {
        Sentry.setUser({ email: userData?.email })
        setStorageUser(userData)
        setLocalUser(usr => ({
          ...usr,
          ...userData,
          role: userData?.role,
          name: [userData.firstname, userData.lastname].join(' '),
        }))
      }
    },
    [setLocalUser]
  )

  const removeUser = useCallback(() => {
    removeStorageUser()
    Sentry.setUser(null)
    setLocalUser(initialValues)
  }, [setLocalUser])

  const context = useMemo(
    () => ({
      user,
      setUser,
      removeUser,
    }),
    [user, setUser, removeUser]
  )

  return <UserContext.Provider value={context}>{children}</UserContext.Provider>
}
UserContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

export default UserContext
