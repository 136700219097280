const env = process.env.REACT_APP_ENV
const backendPort = process.env.REACT_APP_BACKEND_PORT || 3000
const devIP = process.env.REACT_APP_DEV_IP || 'localhost'

const appConfig = {
  apollo: {
    networkInterface: {
      development: `http://${devIP}:${backendPort}/graphql`,
      staging: 'https://rigk-kundenportal-backend-staging.zauberware.com/graphql',
      production: 'https://portal-api.rigk.de/graphql',
    }[env],
    baseUrl: {
      development: `http://${devIP}:${backendPort}`,
      staging: 'https://rigk-kundenportal-backend-staging.zauberware.com',
      production: 'https://portal-api.rigk.de',
    }[env],
  },
}

export default appConfig
