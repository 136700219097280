import React from 'react'
import PropTypes from 'prop-types'
import { ApolloProvider } from '@apollo/client'
import { CssBaseline } from '@mui/material'
import { ThemeProvider as MuiThemeProvider, StyledEngineProvider } from '@mui/material/styles'
import ThemeProvider from '@mui/material/styles/ThemeProvider'
import * as Sentry from '@sentry/react'
import { UserContextProvider } from 'utils/UserContext'
import Localization from '../Localization'
import { BreadcrumbsProvider } from '../utils/BreadcrumbsContext'
import { CustomerProvider } from '../utils/CustomerContext'
import GlobalStyle from '../utils/GlobalStyle'
import MainLayout from './MainLayout'

const style = {
  display: 'flex',
  flexDirection: 'column',
  margin: 'auto',
  height: '100vh',
  width: '100vw',
  textAlign: 'center',
  justifyContent: 'center',
}

const ErrorDisplay = () => (
  <h1 style={style}>An uncaught error appeared. Please contact the development team.</h1>
)

const Layout = ({ ...props }) => (
  <Sentry.ErrorBoundary fallback={ErrorDisplay}>
    <UserContextProvider>
      <ApolloProvider client={props.client}>
        <Localization>
          <StyledEngineProvider injectFirst>
            <MuiThemeProvider theme={props.theme}>
              <ThemeProvider theme={props.theme}>
                <BreadcrumbsProvider>
                  <CustomerProvider>
                    <MainLayout {...props} />
                  </CustomerProvider>
                  <CssBaseline />
                  <GlobalStyle />
                </BreadcrumbsProvider>
              </ThemeProvider>
            </MuiThemeProvider>
          </StyledEngineProvider>
        </Localization>
      </ApolloProvider>
    </UserContextProvider>
  </Sentry.ErrorBoundary>
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  client: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  routes: PropTypes.array.isRequired,
}
export { Layout }

export { MainLayout }
