import React from 'react'
import PropTypes from 'prop-types'
import { Avatar, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import clsx from 'clsx'
import { ArrowRight } from '../../../assets/icons'
import { Link, RoundImage, Text } from '../../atoms'
import Box from './Box'
import Container from './Container'

const useStyles = makeStyles(theme => ({
  textBrown: {
    color: theme.palette.brown,
    padding: '15px 0 10px 0',
  },
  link: {
    color: theme.palette.secondary.main,
    padding: '15px 0',
    fontSize: '0.875rem',
    '&:hover': {
      color: theme.palette.linkHover,
    },
  },
  icon: {
    marginLeft: '3px',
  },
  avatar: { width: '50px', height: '50px' },
  hover: {
    transition: 'all .2s cubic-bezier(.645,.045,.355,1)',
    '&:hover': {
      boxShadow: '0 6px 16px -8px black',
      transition: 'all .2s cubic-bezier(.645,.045,.355,1)',
    },
  },
  external: {
    textDecoration: 'none',
  },
  bold: { fontWeight: 'bold' },
}))

const ColorBox = ({
  color,
  imgSrc,
  imgAlt,
  title,
  subTitle,
  text,
  btnText,
  to,
  href,
  hoverable,
  external,
}) => {
  const classes = useStyles()
  const textStyle = {
    $center: true,
    $color: 'brown',
    fontSize: '0.75rem',
    lineHeight: '1.4rem',
  }
  const content = (
    <Box $color={color}>
      {typeof imgSrc === 'string' ? (
        <RoundImage src={imgSrc} imgAlt={imgAlt} width="50px" height="50px" />
      ) : (
        <Avatar className={classes.avatar} alt={imgAlt}>
          {imgSrc}
        </Avatar>
      )}

      <Typography variant="h2" className={classes.textBrown}>
        {title}
      </Typography>
      <Text {...textStyle} $bold>
        {subTitle}
      </Text>
      <br />
      <Text {...textStyle} $fontWeight="400">
        {text}
      </Text>
    </Box>
  )
  const linkSelector = () => {
    let component = <div {...(hoverable && { className: classes.hover })}>{content}</div>
    if (href && !external) {
      component = (
        <Link to={href} {...(hoverable && { className: classes.hover })}>
          {content}
        </Link>
      )
    }
    if (external && href) {
      component = (
        <a
          className={clsx(hoverable && classes.hover, classes.external)}
          href={href}
          target="_blank"
          rel="noreferrer"
        >
          {content}
        </a>
      )
    }
    return component
  }
  const buttonSelector = () => {
    let component = (
      <Link to={to} className={classes.link} bold>
        {btnText} <ArrowRight className={classes.icon} />
      </Link>
    )
    if (href && !external) {
      component = (
        <Link to={href} className={classes.link} bold>
          {btnText} <ArrowRight className={classes.icon} />
        </Link>
      )
    }
    if (href && external) {
      component = (
        <a
          className={clsx(classes.external, classes.link, classes.bold)}
          href={href}
          target="_blank"
          rel="noreferrer"
        >
          {btnText} <ArrowRight className={classes.icon} />
        </a>
      )
    }

    return component
  }
  return (
    <Container>
      {linkSelector()}
      {btnText && (to || href) && buttonSelector()}
    </Container>
  )
}

ColorBox.propTypes = {
  btnText: PropTypes.string,
  color: PropTypes.string.isRequired,
  imgAlt: PropTypes.string,
  imgSrc: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  subTitle: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  to: PropTypes.string,
  href: PropTypes.string,
  hoverable: PropTypes.bool,
  external: PropTypes.bool,
}
ColorBox.defaultProps = {
  imgAlt: undefined,
  btnText: null,
  to: null,
  href: null,
  hoverable: false,
  external: false,
}

export default ColorBox
