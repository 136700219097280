import React from 'react'
import PropTypes from 'prop-types'
import { LoadingButton } from '@mui/lab'
import { makeStyles } from '@mui/styles'
import clsx from 'clsx'

const useStyles = makeStyles(theme => ({
  button: {
    textTransform: 'none',
    flex: '0 0 auto',
    boxShadow: 'none',
  },
  buttonPrimary: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.black,
    border: `1px solid ${theme.palette.primary.main}`,
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
      color: theme.palette.common.black,
      boxShadow: 'none',
      borderColor: theme.palette.primary.dark,
    },
    '&:disabled': {
      backgroundColor: theme.palette.text.disabled,
      color: theme.palette.common.white,
      border: `1px solid ${theme.palette.text.disabled}`,
      boxShadow: 'none',
      cursor: 'not-allowed',
      pointerEvents: 'inherit',
    },
    '&:focus': {
      boxShadow: `0 0 0 1px ${theme.palette.common.white}, 0 0 0 3px ${theme.palette.primary.main}`,
    },
  },
  buttonSecondary: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.common.white,
    fontWeight: 600,
    border: `1px solid ${theme.palette.secondary.main}`,
    '&:hover': {
      backgroundColor: theme.palette.common.white,
      color: theme.palette.secondary.main,
      boxShadow: 'none',
      borderColor: theme.palette.secondary.main,
    },
    '&:disabled': {
      backgroundColor: theme.palette.text.disabled,
      color: theme.palette.common.white,
      border: `1px solid ${theme.palette.text.disabled}`,
      boxShadow: 'none',
      cursor: 'not-allowed',
      pointerEvents: 'inherit',
    },
    '&:focus': {
      border: '1px solid white',
      boxShadow: `0 0 0 1px ${theme.palette.common.white}, 0 0 0 3px ${theme.palette.secondary.main}`,
    },
  },
}))

const Button = ({
  children,
  color,
  startIcon,
  endIcon,
  disabled,
  type,
  onClick,
  className,
  component,
  loading,
  to: href,
}) => {
  const classes = useStyles()

  return (
    <LoadingButton
      loading={loading}
      variant="contained"
      startIcon={startIcon}
      endIcon={endIcon}
      disabled={disabled}
      className={clsx(
        className,
        classes.button,
        classes.buttonPrimary,
        color === 'secondary' ? classes.buttonSecondary : undefined
      )}
      type={type}
      component={component}
      href={href}
      onClick={onClick}
    >
      {children}
    </LoadingButton>
  )
}
Button.propTypes = {
  loading: PropTypes.bool,
  color: PropTypes.oneOf(['primary', 'secondary']),
  children: PropTypes.node,
  startIcon: PropTypes.node,
  endIcon: PropTypes.node,
  disabled: PropTypes.bool,
  type: PropTypes.string,
  component: PropTypes.any,
  to: PropTypes.string,
  onClick: PropTypes.func,
  className: PropTypes.any,
}
Button.defaultProps = {
  loading: false,
  children: 'Button',
  color: 'primary',
  disabled: false,
  startIcon: null,
  endIcon: null,
  type: undefined,
  className: undefined,
  component: undefined,
  to: undefined,
  onClick: () => {},
}

export default Button
