import React, { useEffect, useState } from 'react'
import { Navigate } from 'react-router-dom'
import { useLazyQuery } from '@apollo/client'
import { Loading } from '../components/atoms'
import { CUSTOMERS_SIDEBAR_QUERY } from '../graphql/queries'

const NO_CUSTOMER_FOUND = 'no-customer-found'

const RedirectComponent = () => {
  const [agreementNo, setAgreementNo] = useState(null)
  const [fetchCustomer] = useLazyQuery(CUSTOMERS_SIDEBAR_QUERY, {
    onCompleted: res => {
      const { customers } = res || {}
      if (customers?.length) {
        const [{ agreementNo: customer }] = customers
        setAgreementNo(customer)
      } else {
        setAgreementNo(NO_CUSTOMER_FOUND)
      }
    },
  })
  useEffect(() => {
    fetchCustomer()
  }, [fetchCustomer])
  if (!agreementNo) return <Loading />
  if (agreementNo === NO_CUSTOMER_FOUND) return <Navigate replace to="/inactive" />
  return <Navigate replace to={`/contracts/contract/${agreementNo}`} />
}
export default RedirectComponent
