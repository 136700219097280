import React, { useContext } from 'react'
import NumberFormat from 'react-number-format'
import PropTypes from 'prop-types'
import makeStyles from '@mui/styles/makeStyles'
import LocaleContext from '../../../utils/LocaleContext'

const useStyles = makeStyles(() => ({
  font: { fontSize: '0.875rem' },
}))
const FormattedNumber = ({
  value,
  suffix,
  prefix,
  decimalScale,
  fixedDecimalScale,
  unsetDecimalScale,
}) => {
  const classes = useStyles()
  const { language } = useContext(LocaleContext)
  return (
    <NumberFormat
      value={value}
      thousandSeparator={language?.name === 'de' ? '.' : ','}
      decimalSeparator={language?.name === 'de' ? ',' : '.'}
      displayType="text"
      decimalScale={!unsetDecimalScale ? decimalScale : undefined}
      isNumericString
      fixedDecimalScale={fixedDecimalScale}
      className={classes.font}
      suffix={suffix}
      prefix={prefix}
    />
  )
}

FormattedNumber.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  suffix: PropTypes.string,
  prefix: PropTypes.string,
  decimalScale: PropTypes.number,
  fixedDecimalScale: PropTypes.bool,
  unsetDecimalScale: PropTypes.bool,
}
FormattedNumber.defaultProps = {
  value: null,
  suffix: null,
  prefix: null,
  decimalScale: 2,
  fixedDecimalScale: false,
  unsetDecimalScale: false,
}

export default FormattedNumber
