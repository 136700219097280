import { NavLink } from 'react-router-dom'
import { styled } from '@mui/material/styles'

const Logo = styled(NavLink)(({ theme, selected }) => ({
  display: 'inline-flex',
  height: '55px',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '0 20px',
  textDecoration: 'none',
  color: selected ? theme.palette.systemBlue : theme.palette.text.primary,
  fontSize: '1.0625rem',
  fontWeight: '700',
  borderBottom: `3px solid ${selected ? theme.palette.systemBlue : 'transparent'}`,
  '&:hover, .selected': {
    color: theme.palette.systemBlue,
    backgroundColor: theme.palette.pickupSystemBlue,
    borderBottomColor: theme.palette.systemBlue,
  },
  backgroundColor: selected ? theme.palette.pickupSystemBlue : 'transparent',
}))

export default Logo
