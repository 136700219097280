import React from 'react'
import PropTypes from 'prop-types'
import { Snackbar } from '../../atoms'

// NOTE: to use multiple MessageBoxes we should use something like notistack
const MessageBox = ({
  horizontal = 'center',
  message,
  open,
  small,
  title,
  variant = 'info',
  vertical = 'bottom',
  autoHideDuration,
  onClose,
  onClick,
  ...rest
}) => (
  <Snackbar
    open={open}
    onClick={onClick}
    onClose={onClose}
    anchorOrigin={{ horizontal, vertical }}
    autoHideDuration={autoHideDuration ?? 5000}
    severity={variant}
    message={message}
    {...rest}
  />
)

MessageBox.propTypes = {
  horizontal: PropTypes.oneOf(['left', 'center', 'right']),
  message: PropTypes.string,
  open: PropTypes.bool.isRequired,
  small: PropTypes.bool,
  title: PropTypes.string,
  variant: PropTypes.oneOf(['error', 'warning', 'info', 'success']),
  vertical: PropTypes.oneOf(['top', 'bottom']),
  autoHideDuration: PropTypes.number,
  onClose: PropTypes.func,
  onClick: PropTypes.func,
}

MessageBox.defaultProps = {
  horizontal: 'center',
  vertical: 'bottom',
  variant: 'info',
}

export default MessageBox
