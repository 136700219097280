import colors from './colors'

const components = {
  MuiButtonBase: {
    defaultProps: {
      // The properties to apply
      disableRipple: true, // No more ripple, on the whole application 💣!
    },
  },
  MuiFormGroup: {
    styleOverrides: {
      row: {
        '& label': {
          paddingRight: 20,
          alignSelf: 'center',
          paddingBottom: 0,
        },
      },
    },
  },
  MuiFormLabel: {
    styleOverrides: {
      root: {
        display: 'block',
        fontSize: '0.875rem',
        fontWeight: 600,
        lineHeight: 1.2,
        paddingBottom: 10,
        '&.Mui-focused': {
          color: colors.textGrey,
        },
      },
    },
  },
  // MuiOutlinedInput: {
  //   styleOverrides: {
  //     input: {
  //       padding: '20px 15px',
  //     },
  //   },
  // },
  MuiPaginationItem: {
    styleOverrides: {
      page: {
        color: colors.brown,
        '&.Mui-selected': {
          background: colors.primaryColor,
        },
      },
      rounded: {
        border: `1px solid ${colors.primaryColor}`,
      },
    },
  },
  MuiDataGrid: {
    styleOverrides: {
      root: {
        border: '1px solid white',
      },
    },
  },
}
export default components
