import React from 'react'
import { useForm, Controller, FormProvider } from 'react-hook-form'
import { useTranslation, Trans } from 'react-i18next'
import PropTypes from 'prop-types'
import { yupResolver } from '@hookform/resolvers/yup'
import { Grid, Typography } from '@mui/material'
import { Button, Input, Checkbox, Select } from '../../atoms'
import CompanyForm from './CompanyForm'
import { useStyles, validationSchema, errorHandler } from './utils'

const RegisterForm = ({ onSubmit, loading }) => {
  const classes = useStyles()
  const { t } = useTranslation('auth')

  const methods = useForm({
    resolver: yupResolver(validationSchema),
    mode: 'onChange',
    reValidateMode: 'onBlur',
  })

  const {
    handleSubmit,
    formState: { errors },
    register,
    control,
  } = methods

  const inputProps = {
    small: true,
    fullWidth: true,
    className: classes.input,
    labelClassName: classes.label,
  }
  const items = [
    { label: t('common:mr'), value: 'Herr' },
    { label: t('common:mrs'), value: 'Frau' },
    { label: t('common:various'), value: 'Divers' },
  ]

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={3}>
          <Grid xs={12} item>
            <Typography variant="h2">{t('register:register')}</Typography>
          </Grid>
          <Grid xs={12} item>
            <Controller
              control={control}
              name="salutation"
              render={({ field }) => (
                <Select
                  {...field}
                  items={items}
                  label={t('register:salutation')}
                  placeholder={t('pleaseSelect')}
                  error={errorHandler(errors, 'salutation')}
                  helperText={t(errorHandler(errors, 'salutation'))}
                  required
                />
              )}
            />
          </Grid>
          <Grid xs={12} sm={6} item>
            <Input
              {...register('firstName')}
              {...inputProps}
              label={t('firstName')}
              error={errorHandler(errors, 'firstName')}
              helperText={t(errorHandler(errors, 'firstName'))}
              className={classes.input}
              required
            />
          </Grid>
          <Grid xs={12} sm={6} item>
            <Input
              {...register('lastName')}
              {...inputProps}
              label={t('lastName')}
              error={errorHandler(errors, 'lastName')}
              helperText={t(errorHandler(errors, 'lastName'))}
              className={classes.input}
              required
            />
          </Grid>
          <Grid xs={12} sm={6} item>
            <Input
              {...register('email')}
              {...inputProps}
              label={t('email')}
              error={errorHandler(errors, 'email')}
              helperText={t(errorHandler(errors, 'email'))}
              className={classes.input}
              required
            />
          </Grid>
          <Grid xs={12} sm={6} item>
            <Input
              type="phone"
              {...register('phone')}
              {...inputProps}
              label={t('phone')}
              error={errorHandler(errors, 'phone')}
              helperText={t(errorHandler(errors, 'phone'))}
              className={classes.input}
            />
          </Grid>

          <CompanyForm />
          <Grid xs={12} item>
            <Controller
              name="terms"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <Checkbox
                  {...field}
                  className={classes.link}
                  checked={field.value}
                  error={errorHandler(errors, 'terms')}
                  helperText={t(errorHandler(errors, 'terms'))}
                  label={
                    <Trans
                      t={t}
                      i18nKey="register:termsLabel"
                      components={{
                        l1: (
                          <a href={t('common:termsLink')} target="_blank" rel="noopener noreferrer">
                            &nbsp;
                          </a>
                        ),
                      }}
                    />
                  }
                />
              )}
            />

            <Controller
              name="privacy"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <Checkbox
                  {...field}
                  className={classes.link}
                  checked={field.value}
                  error={errorHandler(errors, 'privacy')}
                  helperText={t(errorHandler(errors, 'privacy'))}
                  label={
                    <Trans
                      t={t}
                      i18nKey="register:privacyLabel"
                      components={{
                        l1: (
                          <a href={t('common:privacyLink')} target="_blank" rel="noreferrer">
                            &nbsp;
                          </a>
                        ),
                      }}
                    />
                  }
                />
              )}
            />
          </Grid>
          <Grid item>
            <Button type="submit" color="primary" className={classes.button} loading={loading}>
              {t('register:registerAction')}
            </Button>
          </Grid>
          <Grid xs={12} item>
            <Typography variant="body2">{t('auth:mandatoryFields')}</Typography>
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  )
}
RegisterForm.propTypes = {
  onSubmit: PropTypes.func,
  loading: PropTypes.bool,
}
RegisterForm.defaultProps = {
  onSubmit: () => {},
  loading: false,
}

export default RegisterForm
