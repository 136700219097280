import * as Yup from 'yup'

const validationSchema = Yup.object().shape({
  salutation: Yup.string().required('auth:required'),
  firstName: Yup.string().required('auth:required'),
  lastName: Yup.string().required('auth:required'),
  email: Yup.string()
    .email('auth:invalidEmail')
    .required('auth:required')
    .transform(value => value.toLowerCase()),
  requestedCustomers: Yup.array().of(
    Yup.object().shape({
      agreementNo: Yup.string().max(4, 'auth:agreementNoMaxLength').required('auth:required'),
      companyName: Yup.string().required('auth:required'),
    })
  ),
  terms: Yup.boolean().isTrue('auth:required').required('auth:required'),
  privacy: Yup.boolean().isTrue('auth:required').required('auth:required'),
})
export default validationSchema
