import React from 'react'
import PropTypes from 'prop-types'
import { Avatar, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { Mail, Phone } from '../../../assets/icons'
import { Text } from '../../atoms'
import Col from './Col'
import Container from './Container'
import Row from './Row'

const useStyles = makeStyles(theme => ({
  link: {
    textDecoration: 'none',
    color: theme.palette.textGrey,
    '&:visited': {
      color: theme.palette.secondary.main,
    },
    '&:hover': {
      color: theme.palette.secondary.main,
    },
  },
  icon: {
    marginRight: '4px',
  },
  jobTitle: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(3),
    color: theme.palette.textGrey,
  },
}))

const ContactInfo = ({ imgSrc, imgAlt, imgOptions, name, jobTitle, phone, email }) => {
  const classes = useStyles()
  const textStyle = {
    $color: 'inherit',
    fontSize: '0.875rem',
    lineHeight: '1.5',
    fontWeight: '600',
  }
  return (
    <Container>
      <Col padding="15px 15px 0 0">
        <Avatar src={imgSrc} imgAlt={imgAlt} {...imgOptions} />
      </Col>
      <Col>
        <Typography variant="h2">{name}</Typography>
        <Text className={classes.jobTitle} {...textStyle} $italic fontWeight="400">
          {jobTitle}
        </Text>
        <a className={classes.link} href={`tel:${phone}`}>
          <Row>
            <Phone className={classes.icon} />
            <Text {...textStyle}>{phone}</Text>
          </Row>
        </a>
        <a className={classes.link} href={`mailto:${email}`}>
          <Row>
            <Mail className={classes.icon} />
            <Text {...textStyle}>{email}</Text>
          </Row>
        </a>
      </Col>
    </Container>
  )
}

ContactInfo.propTypes = {
  imgAlt: PropTypes.string,
  imgSrc: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  jobTitle: PropTypes.string.isRequired,
  phone: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  imgOptions: PropTypes.object,
}
ContactInfo.defaultProps = {
  imgOptions: null,
  imgAlt: 'Image',
}

export default ContactInfo
