import React from 'react'
// eslint-disable-next-line import/no-extraneous-dependencies
import PhoneInput, { parsePhoneNumber } from 'react-phone-number-input'
import PropTypes from 'prop-types'
import { isString } from 'lodash'
import 'react-phone-number-input/style.css'
import de from 'react-phone-number-input/locale/de'

const phoneNumberInputWithoutLeadingZero = inputValue => {
  if (!isString(inputValue)) return inputValue

  const phoneNumber = parsePhoneNumber(inputValue)

  if (phoneNumber)
    return `+${phoneNumber.countryCallingCode}${phoneNumber.nationalNumber.replace(/^0+/, '')}`

  return inputValue
}

const PhoneNumberInput = ({ inputRef, onChange: onChangeValue, name, ...other }) => (
  <PhoneInput
    {...other}
    flagUrl="/assets/flags/{xx}.svg"
    ref={inputRef}
    onChange={value => {
      onChangeValue({
        target: {
          name,
          value: phoneNumberInputWithoutLeadingZero(value) ?? null,
        },
      })
    }}
    defaultCountry="DE"
    labels={de}
    international
  />
)

PhoneNumberInput.propTypes = {
  inputRef: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
}

export default PhoneNumberInput
