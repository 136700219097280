import React from 'react'
import { Link } from '@mui/material'

const StyledA = props => (
  <Link
    sx={theme => ({
      display: 'inline-flex',
      height: '55px',
      justifyContent: 'center',
      alignItems: 'center',
      padding: '0 20px',
      textDecoration: 'none',
      color: theme.palette.text.primary,
      fontSize: '1.0625rem',
      fontWeight: '700',
      borderBottom: '3px solid transparent',
      '&:hover, .selected': {
        color: theme.palette.systemBlue,
        backgroundColor: theme.palette.pickupSystemBlue,
        borderBottomColor: theme.palette.systemBlue,
      },
      cursor: 'pointer',
      userSelect: 'none',
    })}
    {...props}
  />
)

export default StyledA
