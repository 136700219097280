import moment from 'moment'

export const isLogin = () => !!getToken()

// DEBUG
// export const isLogin = () => true

const LANGUAGE_KEY = 'rigk.language'
export const storeLanguageInLocalStorage = lang => localStorage.setItem(LANGUAGE_KEY, lang)
export const getLanguageFromLocalStorage = () =>
  localStorage.getItem(LANGUAGE_KEY) || browserLanguage()

export const TOKEN_KEY = 'rigk.access-token'
export const setToken = token => localStorage.setItem(TOKEN_KEY, token)
export const getToken = () => localStorage.getItem(TOKEN_KEY)
export const removeToken = () => localStorage.removeItem(TOKEN_KEY)

export const UID_KEY = 'rigk.uid'
export const setUid = uid => localStorage.setItem(UID_KEY, uid)
export const getUid = () => localStorage.getItem(UID_KEY)
export const removeUid = () => localStorage.removeItem(UID_KEY)

export const CLIENT_KEY = 'rigk.client'
export const setClient = cl => localStorage.setItem(CLIENT_KEY, cl)
export const getClient = () => localStorage.getItem(CLIENT_KEY)
export const removeClient = () => localStorage.removeItem(CLIENT_KEY)

export const EXPIRY_KEY = 'rigk.expiry'
export const setExpiry = expiry => localStorage.setItem(EXPIRY_KEY, expiry)
export const getExpiry = () => localStorage.getItem(EXPIRY_KEY)
export const removeExpiry = () => localStorage.removeItem(EXPIRY_KEY)

export const USER_ID_KEY = 'rigk.user.id'
export const getUserId = () => localStorage.getItem(USER_ID_KEY)
export const setUserId = id => localStorage.setItem(USER_ID_KEY, id)
export const removeUserId = id => localStorage.removeItem(USER_ID_KEY, id)

export const USER_KEY = 'rigk.user'
export const setUser = user => localStorage.setItem(USER_KEY, JSON.stringify(user))
export const getUser = () => localStorage.getItem(USER_KEY)
export const removeUser = () => localStorage.removeItem(USER_KEY)

export const TIME_KEY = 'rigk.logout.time'
export const setTimer = time => localStorage.setItem(TIME_KEY, time)
export const getTimer = time => localStorage.getItem(TIME_KEY, time)
export const removeTimer = time => localStorage.removeItem(TIME_KEY, time)

export const clearUserData = client => {
  removeToken()
  removeUid()
  removeClient()
  removeExpiry()
  removeUserId()
  removeUser()
  client?.resetStore?.()
  client?.cache?.reset?.()
}

export const formatNumber = value =>
  Number(value || '0')
    .toFixed(2)
    .replace('.', ',')
export const formatCurrency = value => `${formatNumber(value)} €`

const browserLanguage = () => {
  if (typeof navigator !== 'undefined' && /prerender/i.test(navigator.userAgent)) {
    return 'en'
  }
  const lang = navigator.language || navigator.userLanguage
  return lang.split('-')[0]
}

export const getYearDiff = (start, end) => moment(end).year() - moment(start).year()
