import React from 'react'
import { Global, css, useTheme } from '@emotion/react'

const GlobalStyle = () => {
  const theme = useTheme()
  return (
    <Global
      styles={css`
        body {
          margin: 0;
          font-weight: 300;
          color: ${theme.colorBlack};
          background-color: #efefef;
        }
        #root {
          background-color: white;
          max-width: 120rem;
          width: 100%;
          margin: 0 auto;
        }
        p {
          margin: 0;
        }
      `}
    />
  )
}

export default GlobalStyle
