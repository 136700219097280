import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@mui/styles'
import Claudia from '../assets/images/claudia-hoese.jpg'
import Iryna from '../assets/images/iryna-hrabenko.jpg'
import Maria from '../assets/images/maria-hentze.jpg'
import { ContactBox } from '../components/organisms'

const useStyles = makeStyles(() => ({
  root: {
    width: '100px',
    height: '100px',
    filter: 'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))',
  },
  imgClaudia: {
    objectFit: 'cover',
    objectPosition: '0px 0px',
    width: '160px ',
  },
  imgMaria: {
    objectFit: 'cover',
    objectPosition: '0px 0px',
    width: '160px ',
  },
  imgIryna: {
    width: '100px ',
    height: '100px ',
    objectPosition: '-20px 0px',
  },
}))
const ContactBoxWithData = ({ orientation }) => {
  const classes = useStyles()
  const contacts = [
    {
      name: 'Claudia Hoese',
      email: 'hoese@rigk.de',
      phone: '+ 49 611 308600-12',
      jobTitle: 'Customer Relationship Manager / Public Relations Manager',
      imgSrc: Claudia,
      imgAlt: 'Claudia',
      imgOptions: {
        classes: {
          img: classes.imgClaudia,
          root: classes.root,
        },
      },
    },
    {
      name: 'Maria Hentze',
      email: 'hentze@rigk.de',
      phone: '+49 611 308600-41',
      jobTitle: 'Customer Relationship Manager / Digital Transformation Manager ',
      imgSrc: Maria,
      imgAlt: 'Maria',
      imgOptions: {
        classes: {
          img: classes.imgMaria,
          root: classes.root,
        },
      },
    },
    {
      name: 'Iryna Hrabenko',
      email: 'hrabenko@rigk.de',
      phone: '+49 611 308600-58',
      jobTitle: 'Customer Relationship Manager',
      imgSrc: Iryna,
      imgAlt: 'Iryna',
      imgOptions: {
        classes: {
          img: classes.imgIryna,
          root: classes.root,
        },
      },
    },
  ]
  return <ContactBox orientation={orientation} contacts={contacts} />
}
ContactBoxWithData.propTypes = {
  orientation: PropTypes.oneOf(['column', 'row']),
}
ContactBoxWithData.defaultProps = {
  orientation: undefined,
}
export default ContactBoxWithData
