import lazyWithRetry from '../utils/lazyWithRetry'

const routes = [
  {
    path: '/',
    exact: true,
    isPublic: true,
    restricted: true,
    Component: lazyWithRetry(() => import('./RedirectToLogin')),
  },
  {
    path: '/login',
    exact: true,
    isPublic: true,
    restricted: true,
    Component: lazyWithRetry(() => import('../views/auth/Login')),
  },
  {
    path: '/login-mail-sent',
    exact: true,
    isPublic: true,
    restricted: true,
    withSidebar: true,
    Component: lazyWithRetry(() => import('../views/auth/LoginMailSent')),
  },
  {
    path: '/auth/login-with-token',
    exact: true,
    isPublic: true,
    restricted: true,
    withSidebar: true,
    Component: lazyWithRetry(() => import('../views/auth/LoginWithToken')),
  },
  {
    path: '/resend-confirmation-mail',
    exact: true,
    isPublic: true,
    restricted: true,
    Component: lazyWithRetry(() => import('../views/auth/ResendConfirmationMail')),
  },
  {
    path: '/forgot-password',
    exact: true,
    isPublic: true,
    restricted: true,
    Component: lazyWithRetry(() => import('../views/auth/ForgotPassword')),
  },
  {
    path: '/reset-password',
    exact: true,
    isPublic: true,
    restricted: true,
    Component: lazyWithRetry(() => import('../views/auth/ResetPassword')),
  },
  {
    path: '/register',
    exact: true,
    isPublic: true,
    restricted: true,
    Component: lazyWithRetry(() => import('../views/auth/Register')),
  },
  {
    path: '/confirm-registration',
    exact: true,
    isPublic: true,
    restricted: true,
    Component: lazyWithRetry(() => import('../views/auth/Register/ConfirmRegistration')),
  },
  {
    path: '/confirmed',
    exact: true,
    isPublic: true,
    restricted: false,
    Component: lazyWithRetry(() => import('../views/auth/Register/ConfirmedRegistrationSuccess')),
  },
  {
    path: '/confirmed-registration/success',
    exact: true,
    isPublic: true,
    restricted: true,
    Component: lazyWithRetry(() => import('../views/auth/Register/ConfirmedRegistrationSuccess')),
  },
  {
    path: '/confirmed-registration',
    exact: true,
    isPublic: true,
    restricted: true,
    Component: lazyWithRetry(() => import('../views/auth/Register/ConfirmedRegistration')),
  },
  {
    path: '/logout',
    exact: true,
    isPublic: true,
    restricted: false,
    Component: lazyWithRetry(() => import('../views/auth/Logout')),
  },
  {
    path: '/inactive',
    exact: true,
    isPublic: false,
    Component: lazyWithRetry(() => import('../views/InactiveUser')),
  },
  {
    path: '/contracts/contract/:id',
    exact: true,
    isPublic: false,
    Component: lazyWithRetry(() => import('../views/Dashboard/Contract')),
  },
  {
    path: '/contracts/contract/:id/:systemName',
    exact: true,
    isPublic: false,
    Component: lazyWithRetry(() => import('../views/Dashboard/Contract/Detail')),
  },
  {
    path: '/invoices',
    exact: true,
    isPublic: false,
    Component: lazyWithRetry(() => import('../views/Invoices')),
  },
  {
    path: '/profile',
    exact: true,
    isPublic: false,
    Component: lazyWithRetry(() => import('../views/Profile')),
  },
  {
    path: '*',
    isPublic: true,
    Component: lazyWithRetry(() => import('../views/Route404')),
  },
]

export default routes
