import React from 'react'
import { useTranslation } from 'react-i18next'
import { TopBar } from '../components/molecules'
import { isLogin } from '../utils/helper'
import { useLogout } from '../utils/hooks'

const Header = () => {
  const { t } = useTranslation('common')
  const logout = useLogout()
  const login = isLogin()
  const menuItems = [
    { title: t('backToRigkDe'), to: '', href: 'https://www.rigk.de' },
    login
      ? { title: t('sidebar:logout'), to: '/logout', onClick: logout }
      : { title: t('customerLogin'), to: '/login' },
  ]
  return <TopBar menuItems={menuItems} />
}

export default Header
