import React, { useContext } from 'react'
import { Route, Routes, useLocation } from 'react-router-dom'
import PropTypes from 'prop-types'
import queryString from 'query-string'
import localeContext from '../utils/LocaleContext'
import PrivateRoute from './PrivateRoute'
import PublicRoute from './PublicRoute'

const Router = ({ routes }) => {
  const location = useLocation()
  const { language, setLanguage, availableLanguages } = useContext(localeContext)
  if (location.search) {
    const { locale } = queryString.parse(location.search)
    if (!!locale && language !== locale && availableLanguages.find(l => l.value === locale)) {
      setLanguage(locale)
    }
  }
  return (
    <Routes>
      {routes.map(({ path, Component, exact, isPublic, restricted }) =>
        isPublic ? (
          <Route
            key={path}
            path={path}
            element={
              <PublicRoute
                key={path}
                restricted={restricted}
                component={Component}
                path={path}
                exact={exact}
              />
            }
          />
        ) : (
          <Route
            key={path}
            path={path}
            element={<PrivateRoute key={path} component={Component} path={path} exact={exact} />}
          />
        )
      )}
    </Routes>
  )
}

Router.propTypes = {
  routes: PropTypes.arrayOf(
    PropTypes.shape({
      path: PropTypes.string.isRequired,
      Component: PropTypes.object.isRequired,
      exact: PropTypes.bool,
      isPublic: PropTypes.bool,
      restricted: PropTypes.bool,
    })
  ).isRequired,
}

export default Router
