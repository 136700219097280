import React from 'react'
import PropTypes from 'prop-types'
import { Box } from '@mui/material'

const LogoWrapper = ({ children }) => (
  <Box
    sx={theme => ({
      display: 'flex',
      flexDirection: 'column',
      position: 'relative',
      height: '139px',
      backgroundColor: theme.palette.common.white,
      borderRadius: '0 0 5px 5px',
      boxShadow: theme.shape.boxShadow,
    })}
  >
    {children}
  </Box>
)

LogoWrapper.propTypes = {
  children: PropTypes.node.isRequired,
}

export default LogoWrapper
