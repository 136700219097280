import { gql } from '@apollo/client'

export const ME_QUERY = gql`
  query me {
    me {
      id
      email
    }
  }
`

export const USERS_QUERY = gql`
  query users {
    users {
      collection {
        id
        name
        firstName
        lastName
        email
        role
        isConfirmed
        isLocked
      }
    }
  }
`

export const CUSTOMERS_SIDEBAR_QUERY = gql`
  query customers_sidebar_query {
    customers {
      agreementNo
      agreementStatusDual
      agreementStatusPickup
      agreementStatusRigk
      agreementStatusRigkg
      cityCustomer
      companyCustomer
      createdAt
      emailInvoice
      emailUser
      id
      rgAdress
      streetCustomer
      systems
      updatedAt
      zipCustomer
    }
  }
`

export const HEADER_ADVANCED_NOTICES_QUERY = gql`
  query headerAdvancedNotices($customerId: ID!, $filter: HeaderAdvancedNoticeFilter) {
    headerAdvancedNotices(customerId: $customerId, filter: $filter) {
      collection {
        id
        advancedDateSubmission
        advancedOrderNo
        advancedSystem
        advancedStatus
        agreementNo
        advancedDateStart
        advancedDateEnd
        advancedYear
        advancedMonthsCy
        advancedMonthsFy
        advancedNotes
        downloadLink
        uploads {
          id
          attachmentUrl
          category
          attachmentFilename
        }
      }
      metadata {
        totalCount
      }
    }
  }
`

export const HEADER_FINAL_NOTICES_QUERY = gql`
  query headerAdvancedNotices($customerId: ID!, $filter: HeaderFinalNoticeFilter) {
    headerFinalNotices(customerId: $customerId, filter: $filter) {
      collection {
        id
        finalDateSubmission
        finalOrderNo
        finalSystem
        finalStatus
        agreementNo
        downloadLink
        finalDateStart
        finalDateEnd
        finalYear
        finalMonthsCy
        finalMonthsFy
        finalNotes
        finalWithoutCertificate
        uploads {
          id
          attachmentUrl
          category
          attachmentFilename
        }
      }
      metadata {
        totalCount
      }
    }
  }
`

export const ARTICLES_QUERY = gql`
  query articles($customerId: ID!, $filter: ArticleFilter, $sortBy: ArticleSort) {
    articles(customerId: $customerId, filter: $filter, sortBy: $sortBy) {
      collection {
        id
        articleNameEnglish
        articleNameGerman
        singlePriceCy
        singlePriceFy
        material
        positionAdvancedNotice {
          id
          articleNo
          quantityGerman
          system
          totalPrice
          year
          agreementNo
          headerAdvancedNotice {
            id
          }
        }
        positionFinalNotice {
          id
          articleNo
          quantityGerman
          system
          totalPrice
          year
          agreementNo
          headerFinalNotice {
            id
          }
        }
      }
    }
  }
`

export const INVOICES_QUERY = gql`
  query invoices($filter: InvoiceFilter, $page: Int, $limit: Int, $customerId: ID!) {
    invoices(filter: $filter, page: $page, limit: $limit, customerId: $customerId) {
      metadata {
        totalPages
        currentPage
      }
      collection {
        id
        agreementNo
        system
        year
        invoiceDate
        invoiceNumber
        noticeType
        filePath
        createdAt
        updatedAt
      }
    }
  }
`
