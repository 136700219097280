import { styled } from '@mui/material/styles'
import { bgColor } from '../../../utils/stylesHelper'

const Box = styled('div')(({ $color, theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '15px',
  minHeight: '330px',
  backgroundColor: bgColor($color) || 'inherit',
  borderTop: `5px solid ${theme.palette.text.primary}`,
}))

export default Box
