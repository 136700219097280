import { styled } from '@mui/material/styles'
import { textColor } from '../../../utils/stylesHelper'

const Text = styled('p')(
  ({
    theme,
    $bold,
    $bolder,
    $disabled,
    $italic,
    $color,
    $center,
    lineHeight,
    fontSize,
    fontWeight,
  }) => ({
    lineHeight: lineHeight || '1.6',
    fontSize: fontSize || '1rem',
    fontWeight: 'inherit',
    ...($bold && { fontWeight: 'bold' }),
    ...($bolder && { fontWeight: 'bolder' }),
    ...(fontWeight && { fontWeight }),
    color: 'inherit',
    ...($color && { color: textColor($color) }),
    ...($disabled && { color: theme.palette?.text?.disabled }),
    ...($italic && { fontStyle: 'italic' }),
    fontStyle: $italic ? 'italic' : 'inherit',
    textAlign: $center ? 'center' : 'inherit',
  })
)

export default Text
