import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import PropTypes from 'prop-types'
import { Grid, Typography } from '@mui/material'
import { Input, Button } from '../../atoms'
import ListItem from './ListItem'

const FormItem = React.memo(props => {
  const { control } = useFormContext()
  const { type, name, helperText, disabled, label, options } = props

  const handleFieldChangeFromEvent = ({ target: { value } }, onChange) => {
    onChange(value)
  }

  //   const handleFieldChangeFromValue = (value, onChange) => {
  //     onChange(value)
  //   }

  const listElement = (onChange, value, ref) => (
    <ListItem
      disabled={disabled}
      helperText={helperText}
      label={label}
      onChange={onChange}
      type={type}
      value={value}
      ref={ref}
      {...options}
    />
  )

  const rowListingComponent = (onChange, value) => (
    <Grid container spacing={2}>
      <Grid item xs={4}>
        <Typography variant="caption" color="textPrimary">
          {label}
        </Typography>
      </Grid>
      <Grid item xs={8}>
        {value.join(', ')}
      </Grid>
    </Grid>
  )
  const textfieldComponent = (onChange, value, ref) => (
    <Input
      ref={ref}
      value={value}
      onChange={e => handleFieldChangeFromEvent(e, onChange)}
      multiline
      rows={5}
      fullWidth
      type={type}
      label={label}
      disabled={disabled}
      helperText={helperText}
    />
  )
  const buttonComponent = () => (
    <Grid container spacing={0}>
      <Grid item xs={4}>
        {type === 'leftButton' && (
          <Button type="submit" color="primary" disabled={disabled}>
            {label}
          </Button>
        )}
      </Grid>
      <Grid item xs={4}>
        {type === 'rightButton' && (
          <Button type="submit" color="primary" disabled={disabled}>
            {label}
          </Button>
        )}
      </Grid>
    </Grid>
  )
  const listItemTypes = [
    'defaultListItem',
    'textfieldListItem',
    'addressListItem',
    'listingListItem',
  ]
  const buttonPositions = ['leftButton', 'rightButton']
  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, value, ref } }) => (
        <>
          {type === 'rowListing' && rowListingComponent(onChange, value)}
          {type === 'textfield' && textfieldComponent(onChange, value, ref)}
          {listItemTypes.includes(type) && listElement(onChange, value, ref)}
          {buttonPositions.includes(type) && buttonComponent(onChange, value, ref)}
        </>
      )}
    />
  )
})
FormItem.propTypes = {
  type: PropTypes.string,
  name: PropTypes.string,
  helperText: PropTypes.string,
  disabled: PropTypes.bool,
  label: PropTypes.string,
  options: PropTypes.object,
}
FormItem.defaultProps = {
  type: null,
  name: null,
  helperText: null,
  disabled: false,
  label: null,
  options: null,
}

export default FormItem
