import { useState, useEffect, useCallback, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import moment from 'moment'
import client from '../client'
import config from '../config'
import {
  ME_QUERY,
  HEADER_ADVANCED_NOTICES_QUERY,
  HEADER_FINAL_NOTICES_QUERY,
} from '../graphql/queries'
import BreadcrumbsContext from './BreadcrumbsContext'
import CustomerContext from './CustomerContext'
import { getToken, getUid, getClient, setUser, clearUserData } from './helper'
import UserContext from './UserContext'

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window
  return {
    width,
    height,
  }
}

export default function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions())

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions())
    }

    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return windowDimensions
}

export const useLocalStorageJSON = (key, initialState) => {
  const serializedInitialState = JSON.stringify(initialState)
  let storageValue = initialState
  try {
    storageValue = JSON.parse(localStorage.getItem(key)) ?? initialState
  } catch {
    localStorage.setItem(key, serializedInitialState)
  }
  const [value, setValue] = useState(storageValue)
  const updatedSetValue = useCallback(
    newValue => {
      const serializedNewValue = JSON.stringify(newValue)
      if (serializedNewValue === serializedInitialState || typeof newValue === 'undefined') {
        localStorage.removeItem(key)
      } else {
        localStorage.setItem(key, serializedNewValue)
      }
      setValue(newValue ?? initialState)
    },
    [initialState, serializedInitialState, key]
  )
  return [value, updatedSetValue]
}

export const useLocalStorage = (key, initialState) => {
  const [value, setValue] = useState(localStorage.getItem(key) ?? initialState)
  const updatedSetValue = useCallback(
    newValue => {
      if (newValue === initialState || typeof newValue === 'undefined') {
        localStorage.removeItem(key)
      } else {
        localStorage.setItem(key, newValue)
      }
      setValue(newValue ?? initialState)
    },
    [initialState, key]
  )
  return [value, updatedSetValue]
}

// runs action(location) on location, i.e. route, change
export const useLocationChange = action => {
  const location = useLocation()
  useEffect(() => {
    action(location)
  }, [action, location])
}

export const useFetchExport = ({ headers }) => {
  const [data, setData] = useState(null)
  const [error, setError] = useState(false)
  const [loading, setLoading] = useState(false)
  const [url, setUrl] = useState(undefined)

  useEffect(() => {
    const fetchData = async () => {
      if (!loading) {
        setError(false)
        setLoading(true)
        try {
          let filename
          const result = await fetch(url, {
            method: 'GET',
            headers: { ...headers, 'access-token': getToken(), uid: getUid(), client: getClient() },
          })
            .then(res => {
              // eslint-disable-next-line prefer-destructuring
              filename =
                res.headers.get('content-disposition')?.split('"')?.[1] ||
                res?.url?.split('/').pop()
              return res.blob()
            })
            .then(blob => {
              const downloadUrl = window.URL.createObjectURL(new Blob([blob]))
              const link = document.createElement('a')
              link.href = downloadUrl
              link.setAttribute('download', filename)
              link.click()
              link.parentNode.removeChild(link)
              return blob
            })
            .finally(() => setUrl(undefined))
          setData(result)
        } catch (err) {
          setError(err)
        }
        setLoading(false)
      }
    }

    if (url) fetchData()
  }, [url, headers, loading])
  return [{ data, loading, error }, setUrl]
}

export const useLogout = () => {
  const navigate = useNavigate()
  const logout = () => {
    fetch(`${config.apollo.baseUrl}/auth/sign_out`, {
      method: 'GET',
      headers: {
        'access-token': getToken(),
        uid: getUid(),
        client: getClient(),
      },
    })
      .then(() => {
        client.resetStore()
        client.cache.reset()
        clearUserData(client)
        navigate('/logout')
      })
      .catch(() => {
        clearUserData(client)
        navigate('/logout')
      })
  }

  return logout
}

export const useMe = () => {
  const methods = useQuery(ME_QUERY, {
    fetchPolicy: 'network-only',
    onCompleted: data => {
      setUser(data.me)
    },
    onError: err => console.error('err', err),
  })
  return methods
}

export const useBreadcrumbs = () => {
  const context = useContext(BreadcrumbsContext)
  return context
}

export const useUser = () => {
  const context = useContext(UserContext)
  return context
}

export const useNotices = ({
  agreementNo,
  systemName,
  initialFilter,
  states = ['offen', 'zwischengespeichert', 'nachweisrelevant'],
}) => {
  const [data, setData] = useState(null)
  const [filter, setFilter] = useState(initialFilter || {})
  const { t } = useTranslation(['viewReport', 'table'])
  const { customers } = useContext(CustomerContext)
  const getDateFromString = date => {
    let ret = '-'
    const parsedDate = moment(date)
    if (parsedDate.isValid()) {
      ret = parsedDate.format('YYYY-MM-DD')
    }
    return ret
  }
  useQuery(HEADER_ADVANCED_NOTICES_QUERY, {
    fetchPolicy: 'cache-and-network',
    skip:
      !agreementNo || !customers?.length || !customers?.find(c => c.agreementNo === agreementNo),
    variables: {
      customerId: customers?.find(c => c.agreementNo === agreementNo)?.id,
      filter: {
        advancedYear: filter?.year || undefined,
        advancedSystem: systemName?.toUpperCase() || undefined,
        advancedStatus: filter?.status || undefined,
      },
    },
    onCompleted: result => {
      setData(d => ({
        ...d,
        headerAdvancedNotices: result.headerAdvancedNotices?.collection
          .map(notice => ({
            // ...notice,
            subMissionDate: getDateFromString(notice.advancedDateSubmission),
            year: notice.advancedYear,
            type: t(`viewReport:${notice.__typename}`),
            state: notice.advancedStatus,
            system: notice.advancedSystem,
            agreementNo: notice.agreementNo,
            file: {
              path: notice.uploads?.[0]?.attachmentUrl,
              name: notice.uploads?.[0]?.attachmentFilename,
            },
            key: notice.id,
          }))
          .filter(notice =>
            states.includes(notice.system === systemName.toUpperCase() && notice.state)
          ),
      }))
    },
  })

  useQuery(HEADER_FINAL_NOTICES_QUERY, {
    fetchPolicy: 'cache-and-network',
    skip:
      !agreementNo || !customers?.length || !customers?.find(c => c.agreementNo === agreementNo),
    variables: {
      customerId: customers?.find(c => c.agreementNo === agreementNo)?.id,
      filter: {
        finalYear: filter?.year || undefined,
        finalSystem: systemName?.toUpperCase() || undefined,
        finalStatus: filter?.status || undefined,
      },
    },
    onCompleted: result => {
      setData(d => ({
        ...d,
        headerFinalNotices: result.headerFinalNotices?.collection
          .map(notice => ({
            ...notice,
            subMissionDate: getDateFromString(notice.finalDateSubmission),
            year: notice.finalYear,
            type: notice.__typename,
            state: notice.finalStatus,
            system: notice.finalSystem,
            agreementNo: notice.agreementNo,
            file: {
              path: notice.uploads?.[0]?.attachmentUrl,
              name: notice.uploads?.[0]?.attachmentFilename,
            },
            key: notice.id,
          }))
          .filter(notice =>
            states.includes(notice.system === systemName.toUpperCase() && notice.state)
          ),
      }))
    },
  })

  return { data, filter, setFilter }
}
