import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { Snackbar as MuiSnackbar, Alert as MAlert, AlertTitle as MAlertTitle } from '@mui/material'
import Slide from '@mui/material/Slide'
import withStyles from '@mui/styles/withStyles'

function TransitionUp(props) {
  return <Slide {...props} direction="up" />
}

const Snackbar = ({ message, severity, onClose, anchorOrigin, title, small, ...props }) => {
  const [transition, setTransition] = useState(undefined)
  const [open, setOpen] = useState(false)
  const { t } = useTranslation('common')

  const handleClick = useCallback(
    Transition => {
      setTransition(() => Transition)
      setOpen(true)
    },
    [setTransition, setOpen]
  )

  useEffect(() => {
    if (props.open) {
      handleClick(TransitionUp)
    }
  }, [props.open, handleClick])

  const handleClose = () => {
    setOpen(false)
    onClose?.()
  }

  const alertTitle = () => {
    if (title) return title
    if (severity === 'error') return t('common:error')
    if (severity === 'warning') return t('common:warning')
    if (severity === 'info') return t('common:info')
    if (severity === 'success') return t('common:success')
    return null
  }

  const commonProps = {
    TransitionComponent: transition,
    open,
    onClose: handleClose,
    key: message,
    anchorOrigin: anchorOrigin || {
      horizontal: 'right',
      vertical: 'top',
    },
  }
  const Alert = withStyles(() => ({
    root: {
      paddingLeft: 30,
      paddingRight: 50,
      whiteSpace: 'pre-line',

      alignItems: 'center',
      ...(!small && {
        '& .MuiAlert-icon': {
          fontSize: '2.5rem',
        },
      }),
      fontSize: '0.875rem',
    },
  }))(MAlert)

  const AlertTitle = withStyles(() => ({
    root: {
      padding: 0,
      margin: 0,
      fontSize: '1.125rem',
    },
  }))(MAlertTitle)
  return (
    <MuiSnackbar {...props} {...commonProps}>
      <Alert
        onClose={handleClose}
        sx={{ width: '100%', fontSize: '0.875rem', fontWeight: 'fontWeightRegular' }}
        severity={severity}
      >
        {alertTitle() ? (
          <AlertTitle style={{ padding: 0, margin: 0 }}>{alertTitle()}</AlertTitle>
        ) : null}
        {message}
      </Alert>
    </MuiSnackbar>
  )
}

Snackbar.propTypes = {
  message: PropTypes.string.isRequired,
  severity: PropTypes.oneOf(['error', 'warning', 'info', 'success']),
  onClose: PropTypes.func,
  anchorOrigin: PropTypes.shape({
    horizontal: PropTypes.oneOf(['left', 'center', 'right']),
    vertical: PropTypes.oneOf(['top', 'bottom']),
  }),
  title: PropTypes.string,
  small: PropTypes.bool,
}

Snackbar.defaultProps = {
  severity: 'info',
  onClose: () => {},
  anchorOrigin: {
    horizontal: 'right',
    vertical: 'top',
  },
  title: '',
  small: false,
}

export default Snackbar
