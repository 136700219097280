import { styled, css } from '@mui/material/styles'

const DatePickerWrapper = styled('div')(
  ({ theme, $fullWidth }) => css`
    display: flex;
    flex-direction: row;

    ${$fullWidth &&
    css`
      .react-datepicker-wrapper {
        width: 100%;
      }
    `}

    .react-datepicker-popper {
      z-index: ${theme.zIndex.tooltip};
    }

    .react-datepicker {
      border: none;
      box-shadow: 0 4px 20px 0 ${theme.palette.grey[500]};
      font-family: ${theme.fontFamily};
      background: white;
      padding-top: 8px;

      & .react-datepicker__navigation {
        top: 18px;
        outline: none;
      }

      & .react-datepicker__header {
        background: white;
        border: none;
      }

      & .react-datepicker__current-month {
        color: ${theme.palette.black};
        font-weight: normal;
      }

      & .react-datepicker__day--keyboard-selected {
        background-color: ${theme.palette.grey[300]};
      }

      & .react-datepicker__day-name {
        color: ${theme.palette.grey[300]};
      }

      & .react-datepicker__day--in-selecting-range {
        color: ${theme.palette.common.black};
        background-color: ${theme.palette.grey[50]};
      }

      & .react-datepicker__day {
        border-radius: 50%;
        outline: none;
      }

      & .react-datepicker__day:hover {
        color: ${theme.palette.common.black};
        background-color: ${theme.palette.grey[50]};
      }

      & .react-datepicker__day--selected {
        color: ${theme.palette.common.white};
        background-color: ${theme.palette.common.black};
      }

      & .react-datepicker__day--selecting-range-start,
      .react-datepicker__day--selecting-range-end {
        color: ${theme.palette.common.black};
        background-color: ${theme.palette.grey[100]};
      }

      & .react-datepicker__day--in-range {
        color: ${theme.palette.common.black};
        background-color: ${theme.palette.grey[100]};
      }

      & .react-datepicker__day--range-start,
      .react-datepicker__day--range-end {
        color: ${theme.palette.common.white};
        background-color: ${theme.palette.common.black};
      }

      & .react-datepicker__day--in-range:hover {
        color: ${theme.palette.common.white};
        background-color: ${theme.palette.common.black};
      }
    }
  `
)
export default DatePickerWrapper
