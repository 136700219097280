import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { FormControl, MenuItem, FormLabel, FormGroup, FormHelperText } from '@mui/material'
import { makeStyles } from '@mui/styles'
import clsx from 'clsx'
import { ArrowDown } from '../../../assets/icons'
import CustomSelect from './CustomSelect'

const useStyles = makeStyles(() => ({
  italic: {
    '& > .MuiInputLabel-outlined': {
      fontStyle: 'italic',
    },
  },
  root: {
    '& label + .MuiInput-formControl': {
      marginTop: 12,
    },
  },
  borderless: {
    fontWeight: 'bold',
    '& fieldset': {
      border: 'none',
    },
  },
}))

const Select = React.forwardRef(
  (
    {
      children,
      disabled,
      italic,
      items,
      label,
      minWidth,
      maxWidth,
      multiple,
      onChange,
      value: propValue,
      placeholder,
      className,
      helperText,
      labelPlacement,
      borderless,
      selectClass,
      labelClass,
      error,
      showOnlyField,
      required,
    },
    ref
  ) => {
    const classes = useStyles()
    const [value, setValue] = useState(propValue)

    const renderValue = selected => {
      if (showOnlyField?.value === selected) {
        return showOnlyField?.label || showOnlyField?.value
      }
      return items?.find(item => item.value === selected)?.label
    }
    useEffect(() => {
      if (propValue !== value) setValue(propValue)
    }, [propValue, setValue, value])

    const placeholderObject = { label: <em>{placeholder}</em>, value: '' }
    const itemsWithPlaceholder = placeholder ? [placeholderObject, ...items] : items

    return (
      <FormControl
        className={clsx(className, italic ? classes.italic : classes.root)}
        variant="outlined"
        style={{ minWidth }}
        ref={ref}
      >
        <FormGroup aria-label="position" row={labelPlacement === 'row'}>
          <FormLabel className={labelClass} component="label">
            {label}
            {required && '*'}
          </FormLabel>
          <CustomSelect
            className={clsx(borderless ? classes.borderless : undefined, selectClass)}
            id="select-outlined"
            maxWidth={maxWidth}
            minWidth={minWidth}
            multiple={multiple}
            value={value}
            {...(showOnlyField && {
              renderValue,
            })}
            onChange={({ target: { value: newValue } }) => onChange(newValue)}
            disabled={disabled}
            displayEmpty
            IconComponent={props => <ArrowDown {...props} style={{ top: 'inherit' }} />}
          >
            {items?.length
              ? itemsWithPlaceholder?.map(item => (
                  <MenuItem key={item.value} value={item.value}>
                    {item.label}
                  </MenuItem>
                ))
              : children}
          </CustomSelect>
        </FormGroup>
        <FormHelperText error={error}>{helperText}</FormHelperText>
      </FormControl>
    )
  }
)
Select.propTypes = {
  children: PropTypes.node,
  disabled: PropTypes.bool,
  italic: PropTypes.bool,
  items: PropTypes.arrayOf(
    PropTypes.shape({ value: PropTypes.any.isRequired, label: PropTypes.string.isRequired })
  ),
  label: PropTypes.string,
  className: PropTypes.string,
  minWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  maxWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  multiple: PropTypes.bool,
  borderless: PropTypes.bool,
  onChange: PropTypes.func,
  value: PropTypes.any,
  placeholder: PropTypes.string,
  helperText: PropTypes.string,
  labelPlacement: PropTypes.oneOf(['row', 'column']),
  selectClass: PropTypes.string,
  labelClass: PropTypes.string,
  error: PropTypes.bool,
  showOnlyField: PropTypes.shape({
    value: PropTypes.any,
    label: PropTypes.string,
  }),
  required: PropTypes.bool,
}
Select.defaultProps = {
  children: null,
  labelPlacement: null,
  disabled: false,
  italic: false,
  className: undefined,
  items: undefined,
  label: null,
  minWidth: 100,
  maxWidth: 180,
  multiple: false,
  borderless: false,
  onChange: null,
  value: '',
  placeholder: null,
  helperText: undefined,
  selectClass: undefined,
  labelClass: undefined,
  error: false,
  showOnlyField: undefined,
  required: false,
}

export default Select
