import colors from './colors'

const textColors = {
  ...colors,
  primary: colors.primaryColor,
  secondary: colors.secondaryColor,
  pending: colors.gSystemOrange,
  presaved: colors.primary,
  done: colors.textGrey,
  inReview: colors.red,
}

// eslint-disable-next-line import/prefer-default-export
export const textColor = name => (textColors[name] ? textColors[name] : null)

export const bgColor = name => (colors[name] ? colors[name] : null)
