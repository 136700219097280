import { styled } from '@mui/material/styles'

const LogoText = styled('div')({
  bottom: '10px',
  paddingLeft: '16px',
  position: 'absolute',
  fontWeight: 'bold',
  color: '#0079ba',
})

export default LogoText
