import PropTypes from 'prop-types'
import { styled } from '@mui/material/styles'

const RoundImage = styled('img')(({ width, height }) => ({
  width,
  height,
  borderRadius: '50%',
}))
RoundImage.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
}

RoundImage.defaultProps = {
  width: '100px',
  height: '100px',
}
export default RoundImage
