import React, { useState, useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Tooltip } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import clsx from 'clsx'
import colors from '../../../utils/colors'

const OverflowTip = ({ children, color, className, onClick }) => {
  const useStyles = makeStyles(() => ({
    ellipsis: {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  }))
  const classes = useStyles()
  const [overflowed, setOverflowed] = useState(false)
  const textElement = useRef(null)
  useEffect(() => {
    setOverflowed(textElement.current.scrollWidth > textElement.current.clientWidth)
  }, [])

  return (
    <Tooltip title={children} disableHoverListener={!overflowed} onClick={onClick}>
      <div
        ref={textElement}
        className={clsx(classes.ellipsis, className)}
        style={{ color: colors[color] ?? colors.textGrey }}
      >
        {children}
      </div>
    </Tooltip>
  )
}
OverflowTip.propTypes = {
  children: PropTypes.node.isRequired,
  color: PropTypes.string,
  className: PropTypes.string,
  onClick: PropTypes.func,
}

OverflowTip.defaultProps = {
  color: null,
  className: null,
  onClick: () => {},
}

export default OverflowTip
