import React from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { DataGrid } from '@mui/x-data-grid'
import { Info } from '../../../assets/icons'

const useStyles = makeStyles(theme => ({
  root: {
    '& .MuiDataGrid-columnSeparator': {
      color: 'white',
    },
    '& .MuiDataGrid-columnHeaderTitle': {
      fontWeight: 'bold',
      color: theme.palette.text.primary,
      fontSize: theme.fontSize.small,
    },
    '& .MuiDataGrid-columnHeaderTitleContainer': {
      padding: 0,
    },
    '& .MuiDataGrid-cell': {
      fontSize: theme.fontSize.small,
      fontWeight: 'normal',
    },
  },
  orange: { color: theme.palette.error.light },
  emptyRows: {
    display: 'flex',
    paddingTop: 70,
    paddingLeft: 40,
    alignItems: 'center',
  },
  rightSpacing: { marginRight: 20 },
}))

const Table = ({ columns, rows, isCellEditable, loading }) => {
  const { t } = useTranslation('common')
  const classes = useStyles()

  const NoRowsOverlay = () => (
    <div className={classes.emptyRows}>
      <Info className={classes.rightSpacing} />
      <Typography variant="h2" className={classes.orange}>
        {t('emptyTable')}
      </Typography>
    </div>
  )

  return (
    <DataGrid
      components={{
        NoRowsOverlay,
      }}
      disableColumnMenu
      disableColumnResize
      autoHeight
      isCellEditable={isCellEditable}
      hideFooterPagination
      className={classes.root}
      columns={columns}
      rows={rows}
      disableSelectionOnClick
      loading={loading}
    />
  )
}
Table.propTypes = {
  columns: PropTypes.array.isRequired,
  rows: PropTypes.array,
  isCellEditable: PropTypes.bool,
  loading: PropTypes.bool,
}
Table.defaultProps = {
  rows: [],
  isCellEditable: false,
  loading: false,
}

export default Table
