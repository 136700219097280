import React, { Fragment, useState } from 'react'
import { useFormContext, Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined'
import { Grid, Button as MuiButton, Typography } from '@mui/material'
import { Input } from '../../atoms'
import { useStyles, errorHandler } from './utils'

const CompanyForm = () => {
  const [count, setCount] = useState([0])
  const classes = useStyles()
  const { t } = useTranslation('auth')
  const methods = useFormContext()
  const {
    formState: { errors },
    setValue,
    unregister,
    control,
  } = methods
  const inputProps = {
    small: true,
    fullWidth: true,
    className: classes.input,
    labelClassName: classes.label,
  }
  return (
    <>
      {count?.map(i => (
        <Fragment key={i}>
          <Grid xs={12} md={6} item key={`contractNumber${i}`}>
            <Controller
              control={control}
              name={`requestedCustomers[${i}].agreementNo`}
              render={({ field: { onChange, value, ref } }) => (
                <Input
                  {...inputProps}
                  ref={ref}
                  onChange={onChange}
                  value={value}
                  label={t('register:contractNumber')}
                  error={errorHandler(errors, 'requestedCustomers.agreementNo', i)}
                  helperText={t(errorHandler(errors, 'requestedCustomers.agreementNo', i))}
                  required
                />
              )}
            />
          </Grid>
          <Grid xs={12} md={6} item container key={`contractCompany${i}`}>
            <Grid xs={12} md={count.length > 1 ? 11 : 12} item>
              <Controller
                control={control}
                name={`requestedCustomers[${i}].companyName`}
                render={({ field: { onChange, value, ref } }) => (
                  <Input
                    {...inputProps}
                    ref={ref}
                    onChange={onChange}
                    value={value}
                    label={t('register:contractCompany')}
                    error={errorHandler(errors, 'requestedCustomers.companyName', i)}
                    helperText={t(errorHandler(errors, 'requestedCustomers.companyName', i))}
                    required
                  />
                )}
              />
            </Grid>
            {count.length > 1 && (
              <Grid item xs={12} md={1}>
                <MuiButton
                  onClick={() => {
                    setValue(`requestedCustomers[${i}].companyName`, undefined)
                    setValue(`requestedCustomers[${i}].agreementNo`, undefined)
                    setCount(count.filter(c => c !== i))
                    unregister(`requestedCustomers[${i}].companyName`)
                    unregister(`requestedCustomers[${i}].agreementNo`)
                  }}
                  className={classes.deleteButton}
                >
                  <HighlightOffOutlinedIcon />
                </MuiButton>
              </Grid>
            )}
          </Grid>
        </Fragment>
      ))}
      <Grid xs={12} item>
        <div className={classes.linkLikeButton}>
          <MuiButton
            onClick={() => {
              const newNum = count?.[count.length - 1] + 1 || 0
              const retArray = [...count, newNum]
              setCount(retArray)
            }}
          >
            <Typography variant="caption">{t('register:addAnotherContractNo')}</Typography>
          </MuiButton>
        </div>
      </Grid>
    </>
  )
}

export default CompanyForm
