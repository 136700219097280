import { styled } from '@mui/material/styles'

const Box = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  width: '300px',
  height: '100px',
})

export default Box
