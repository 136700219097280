import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import queryString from 'query-string'
import MessageBox from '../MessageBox'

const GlobalErrorHandler = () => {
  const { location } = window
  const { t } = useTranslation('common')
  const [errorMessage, setErrorMessage] = useState()
  const [noticeMessage, setNoticeMessage] = useState()
  useEffect(() => {
    const { error, notice } = queryString.parse(location.search)
    if (error) setErrorMessage(error)
    if (notice) setNoticeMessage(notice)
  }, [location])
  return (
    <>
      <MessageBox
        message={errorMessage}
        title={t('error')}
        open={errorMessage}
        variant="error"
        autoHideDuration={5000}
        onClose={() => {
          setErrorMessage(undefined)
        }}
      />
      <MessageBox
        message={noticeMessage}
        title={t('notice')}
        open={noticeMessage}
        variant="info"
        autoHideDuration={5000}
        onClose={() => {
          setNoticeMessage(undefined)
        }}
      />
    </>
  )
}

export default GlobalErrorHandler
