import React, { useState } from 'react'
import PropTypes from 'prop-types'

export const defaultContextValue = {
  customers: [],
  setCustomers: () => {},
  setData: () => {},
  user: {},
  setUser: () => {},
}

const CustomerContext = React.createContext(defaultContextValue)

export const CustomerProvider = ({ children }) => {
  const [customers, setCustomers] = useState(defaultContextValue.customers)
  const setCustomer = customer => {
    setCustomers(cs => cs.map(c => (c.id === customer.id ? customer : c)))
  }
  const getCustomer = id => customers?.find(c => c.id === id)
  const getCustomerByAgreementNo = agreementNo =>
    customers?.find(c => c.agreementNo === agreementNo)

  const [user, setUser] = useState(defaultContextValue.user)

  const [data, setData] = useState({})
  const context = {
    user,
    setUser,
    customers,
    setCustomers,
    data,
    setData,
    setCustomer,
    getCustomer,
    getCustomerByAgreementNo,
  }

  return <CustomerContext.Provider value={context}>{children}</CustomerContext.Provider>
}
CustomerProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

export default CustomerContext
