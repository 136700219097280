import React, { useCallback, useState, useEffect } from 'react'
import { useDropzone } from 'react-dropzone'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { FormLabel, Typography, CircularProgress, FormHelperText } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { DirectUpload } from '@rails/activestorage'
import clsx from 'clsx'
import { Checked, Delete, Plus, Upload } from '../../../assets/icons'
import config from '../../../config'
import OverflowTip from '../OverflowTip'

const DIRECT_UPLOAD_URL = `${config.apollo.baseUrl}/direct_uploads`

const useStyles = makeStyles(theme => ({
  container: {
    color: theme.palette.secondary.main,
    cursor: 'pointer',
    maxWidth: '100%',
    paddingBottom: theme.spacing(2),
  },
  row: {
    display: 'flex',
    alignItems: 'center',
  },
  newFile: {
    color: theme.palette.secondary.main,
  },
  text: {
    paddingLeft: theme.spacing(2),
  },
  typography: {
    fontSize: '0.875rem',
    fontWeight: 400,
    lineHeight: 1.5,
  },
  disabled: {
    color: theme.palette.grey[100],
    cursor: 'not-allowed',
  },
  noFileText: {
    color: theme.palette.error.main,
    fontSize: '0.625rem',
  },
}))

const Uploader = React.forwardRef(
  (
    {
      accept,
      label,
      required,
      maxFiles,
      maxSize,
      multiple,
      disabled,
      noFileText,
      onFilesChanged,
      onFileDeleted,
      name,
      value,
      labelClassName,
      error,
      helperText,
    },
    ref
  ) => {
    const [errorMessage, setErrorMessage] = useState()
    const classes = useStyles()
    const { t } = useTranslation('common')
    const [hovering, setHovering] = useState(null)
    const [uploading, setUploading] = useState(false)

    const [files, setFiles] = useState([])
    useEffect(() => {
      if (value?.length !== files?.length) setFiles(value)
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value, setFiles])

    useEffect(() => {
      if (files?.length) onFilesChanged?.(files)
    }, [files, onFilesChanged])

    const deleteFile = (file, e) => {
      e.stopPropagation()
      setHovering(null)
      setFiles(files.filter(f => f.filename !== file.filename))
      onFileDeleted?.(file, name)
    }

    const browserFileTypes = [
      'image/gif',
      'image/png',
      'image/jpeg',
      'text/plain',
      'application/pdf',
    ]

    const getPreviewFromUrl = async url => {
      if (url.includes('blob:')) window.open(url, '_blank')
      else {
        const blob = await fetch(url).then(r => r.blob())
        window.open(
          browserFileTypes.includes(blob.type) ? URL.createObjectURL(blob) : url,
          '_blank'
        )
      }
    }

    const onDrop = useCallback(
      newFiles => {
        setErrorMessage(false)
        setUploading(true)
        newFiles.forEach((newFile, i) => {
          const upload = new DirectUpload(newFile, DIRECT_UPLOAD_URL)

          return upload.create((err, newBlob) => {
            if (err) {
              console.error('err', err)
              return
            }

            setFiles(f => [
              ...(f || []),
              { ...newBlob, name, attachmentUrl: URL.createObjectURL(newFile) },
            ])
            if (newFiles.length - 1 === i) setUploading(false)
          })
        })
      },
      [setFiles, name]
    )
    const onDropRejected = () => {
      setUploading(false)
      setErrorMessage(
        t('reachedMaxFileSize', {
          defaultValue: 'Datei zu groß, maximale Uploadgröße {{size}}',
          size: getMaxFileSize(maxSize),
        })
      )
    }
    const { getRootProps, getInputProps } = useDropzone({
      onDrop,
      accept,
      maxFiles,
      maxSize,
      multiple,
      onDropRejected,
    })
    const getIcon = () => {
      let ret = <Upload />
      if (files.length) ret = <Plus />
      if (uploading) ret = <CircularProgress />
      return ret
    }

    const getNumberBelowThousand = (val, count = 0) => {
      const sizes = ['B', 'KB', 'MB', 'GB']
      if (val < 1024) return { size: sizes[count], num: val }
      return getNumberBelowThousand(val / 1024, count + 1)
    }
    const getMaxFileSize = val => {
      const { size, num } = getNumberBelowThousand(val)

      return `${num.toFixed(1)} ${size}`
    }

    return (
      <div
        ref={ref}
        {...getRootProps()}
        className={clsx(classes.container, disabled && classes.disabled)}
      >
        <FormLabel component="label" className={labelClassName}>
          {label}
          {required ? '*' : ''}
        </FormLabel>
        {maxFiles === 0 || files.length < maxFiles ? (
          <input {...getInputProps()} disabled={disabled || uploading} hidden={uploading} />
        ) : null}
        {files?.map(file => (
          <div key={file?.filename} className={clsx(classes.row, classes.newFile)}>
            {/* eslint-disable-next-line jsx-a11y/mouse-events-have-key-events */}
            <div
              onMouseOver={() => setHovering(file?.signed_id)}
              onMouseOut={() => setHovering(null)}
              className={classes.row}
            >
              {hovering === file?.signed_id ? (
                <Delete onClick={e => deleteFile(file, e)} />
              ) : (
                <Checked />
              )}
            </div>
            <OverflowTip
              color="secondaryColor"
              className={clsx(classes.text, classes.typography)}
              onClick={() => getPreviewFromUrl(file.attachmentUrl)}
            >
              {file?.filename}
            </OverflowTip>
          </div>
        ))}
        {maxFiles === 0 || files.length < maxFiles ? (
          <div className={classes.row}>
            {getIcon()}
            <Typography className={classes.text}>
              {files.length ? t('uploadAdditionalDocument') : t('clickToSelectFile')}
            </Typography>
          </div>
        ) : null}
        {files.length <= 0 && noFileText && (
          <Typography className={classes.noFileText}>{noFileText}</Typography>
        )}
        <FormHelperText error={error}>{helperText}</FormHelperText>
        {errorMessage && <FormHelperText error>{errorMessage}</FormHelperText>}
      </div>
    )
  }
)
Uploader.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  accept: PropTypes.string,
  required: PropTypes.bool,
  multiple: PropTypes.bool,
  disabled: PropTypes.bool,
  noFileText: PropTypes.string,
  maxFiles: PropTypes.number,
  maxSize: PropTypes.number,
  onFilesChanged: PropTypes.func,
  onFileDeleted: PropTypes.func,
  value: PropTypes.array,
  labelClassName: PropTypes.string,
  error: PropTypes.bool,
  helperText: PropTypes.string,
}
Uploader.defaultProps = {
  accept: null,
  label: null,
  name: null,
  required: false,
  multiple: true,
  disabled: false,
  noFileText: null,
  maxFiles: 0,
  maxSize: 30 * 1024 * 1024,
  onFilesChanged: () => {},
  onFileDeleted: () => {},
  value: [],
  labelClassName: null,
  error: false,
  helperText: null,
}

export default Uploader
