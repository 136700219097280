import React, { useEffect, useState, forwardRef } from 'react'
import PropTypes from 'prop-types'
import CheckBoxIcon from '@mui/icons-material/CheckBox'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import { Checkbox, FormControlLabel, FormHelperText } from '@mui/material'

const CheckBox = forwardRef(
  (
    {
      className,
      checked: propChecked,
      onChange,
      name,
      label,
      multilineLabel,
      helperText,
      error,
      ...rest
    },
    ref
  ) => {
    const [checked, setChecked] = useState(propChecked)
    useEffect(() => {
      setChecked(propChecked)
    }, [propChecked])

    const handleChange = e => {
      setChecked(e.target.checked)
      if (onChange) onChange(e.target.checked, e.target.name)
    }

    return (
      <>
        <FormControlLabel
          className={className}
          style={{ display: multilineLabel ? 'table' : null }}
          control={
            <div style={{ display: multilineLabel ? 'table-cell' : null }}>
              <Checkbox
                {...rest}
                ref={ref}
                checked={checked}
                color="secondary"
                icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                checkedIcon={<CheckBoxIcon fontSize="small" />}
                onChange={handleChange}
                name={name}
              />
            </div>
          }
          label={label}
        />
        <FormHelperText error={error}>{helperText}</FormHelperText>
      </>
    )
  }
)

CheckBox.propTypes = {
  checked: PropTypes.bool,
  onChange: PropTypes.func,
  name: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  multilineLabel: PropTypes.bool,
  className: PropTypes.string,
  helperText: PropTypes.string,
  error: PropTypes.bool,
}
CheckBox.defaultProps = {
  checked: false,
  onChange: null,
  name: 'CheckBox',
  label: '',
  multilineLabel: false,
  className: null,
  helperText: undefined,
  error: false,
}

export default CheckBox
