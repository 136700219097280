import React from 'react'
import CookieConsent from 'react-cookie-consent'
import { useTranslation, Trans } from 'react-i18next'
import { Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import Button from '../Button'

const useStyles = makeStyles(theme => ({
  container: {
    backgroundColor: `${theme.palette.primary.main} !important`,
    color: theme.palette.textGrey,
    fontSize: '0.75rem',
  },
  button: {
    paddingRight: 10,
    alignSelf: 'center',
  },
  link: {
    lineHeight: '1.375rem',
    '& a': {
      color: theme.palette.secondary.main,
      textDecoration: 'none',
      '&:visited': {
        color: theme.palette.secondary.main,
      },
      '&:hover': {
        color: theme.palette.textGrey,
      },
    },
  },
}))
const CookieBanner = () => {
  const { t } = useTranslation('common')
  const classes = useStyles()

  const SecondaryButton = props => (
    <Button color="secondary" {...props}>
      {t('cookieSubmit')}
    </Button>
  )

  return (
    <CookieConsent
      ButtonComponent={SecondaryButton}
      contentClasses={classes.container}
      containerClasses={classes.container}
      buttonWrapperClasses={classes.button}
      location="bottom"
      buttonText="Ok"
      cookieName="portal.rigk.de.consent"
      expires={150}
    >
      <Typography variant="caption">{t('cookieTitle')}</Typography>
      <Typography className={classes.link}>
        <Trans
          t={t}
          i18nKey="cookieContent"
          components={{
            l: (
              <a href={t('common:privacyLink')} target="_blank" rel="noreferrer">
                &nbsp;
              </a>
            ),
          }}
        />
      </Typography>
    </CookieConsent>
  )
}

export default CookieBanner
