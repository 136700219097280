import { styled } from '@mui/material/styles'

const StyledButton = styled('button')(({ theme }) => ({
  display: 'inline-flex',
  height: '55px',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '0 20px',
  textDecoration: 'none',
  color: theme.palette.text.primary,
  fontSize: '1.0625rem',
  fontWeight: '700',
  fontFamily: theme.fontFamily,
  border: 'none',
  borderBottom: '3px solid transparent',
  '&:hover': {
    color: theme.palette.systemBlue,
    backgroundColor: theme.palette.pickupSystemBlue,
    borderBottomColor: theme.palette.systemBlue,
  },
  backgroundColor: 'transparent',
  cursor: 'pointer',
}))

export default StyledButton
