import React, { useState, useEffect } from 'react'
import NumberFormat from 'react-number-format'
import PropTypes from 'prop-types'
import { FormLabel, TextField } from '@mui/material'
import { makeStyles } from '@mui/styles'
import PhoneNumberInput from './PhoneNumberInput'

const useStyles = makeStyles(theme => ({
  phone: {
    '& .PhoneInput': { display: 'flex' },
    '& .PhoneInputInput': {
      border: 'none',
      fontFamily: theme.fontFamily,
      font: 'inherit',
      color: 'currentColor',
    },
    '& .PhoneInputInput::focus-visible': {
      backgroundColor: 'lime',
    },
    '& .PhoneInput--focus input': {
      outline: 'none !important',
    },
  },
}))

const CustomNumber = ({
  inputRef,
  onChange: onChangeValue,
  name,
  fixedDecimalScale,
  maxNumericValue,
  ...other
}) => (
  <NumberFormat
    {...other}
    getInputRef={inputRef}
    onValueChange={({ value }) => {
      onChangeValue({
        target: {
          name,
          value: maxNumericValue ? Math.min(value, maxNumericValue) : value,
        },
      })
    }}
    thousandSeparator="."
    decimalSeparator=","
    isNumericString
    fixedDecimalScale={fixedDecimalScale}
  />
)

CustomNumber.propTypes = {
  inputRef: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  fixedDecimalScale: PropTypes.bool,
  maxNumericValue: PropTypes.number,
}
CustomNumber.defaultProps = {
  fixedDecimalScale: false,
  maxNumericValue: null,
}

const Input = React.forwardRef(
  (
    {
      disabled,
      fullWidth,
      label,
      maxNumericValue,
      multiline,
      onChange,
      rows,
      showPassword,
      small,
      type,
      className,
      classes,
      labelClassName,
      value,
      defaultValue,
      name,
      error,
      autoFocus,
      helperText,
      required,
      placeholder,
      InputProps,
      decimalScale,
      fixedDecimalScale,
      maxLength,
    },
    ref
  ) => {
    const [visible, setVisible] = useState(false)
    const styles = useStyles()
    useEffect(() => {
      setVisible(showPassword)
    }, [showPassword])

    const defaultFieldProps = {
      variant: 'outlined',
      disabled,
      autoFocus,
      name,
      onChange,
      size: small ? 'small' : 'medium',
      value,
      defaultValue,
      fullWidth,
      multiline,
      rows,
      className,
      classes,
      error,
      helperText,
      placeholder,
      InputProps: {
        inputProps: {
          maxLength,
        },
      },
    }

    const numberFieldProps = {
      InputProps: {
        inputComponent: CustomNumber,
        ...InputProps,
        inputProps: {
          fixedDecimalScale,
          decimalScale,
          maxNumericValue,
        },
      },
    }

    const moneyFieldProps = {
      InputProps: {
        endAdornment: '€',
        inputComponent: CustomNumber,
        ...InputProps,
        inputProps: {
          fixedDecimalScale,
          decimalScale,
          maxNumericValue,
        },
      },
    }

    const phoneFieldProps = {
      InputProps: {
        inputComponent: PhoneNumberInput,
      },
      InputLabelProps: { shrink: true },
      className: styles.phone,
    }

    const passwordFieldProps = { type: visible ? 'test' : 'password' }

    let textFieldComponent = <TextField inputRef={ref} {...defaultFieldProps} />

    if (type === 'number') {
      textFieldComponent = <TextField inputRef={ref} {...defaultFieldProps} {...numberFieldProps} />
    }

    if (type === 'money') {
      textFieldComponent = <TextField inputRef={ref} {...defaultFieldProps} {...moneyFieldProps} />
    }

    if (type === 'password') {
      textFieldComponent = (
        <TextField inputRef={ref} {...defaultFieldProps} {...passwordFieldProps} />
      )
    }
    if (type === 'phone') {
      textFieldComponent = <TextField inputRef={ref} {...defaultFieldProps} {...phoneFieldProps} />
    }

    return (
      <>
        <FormLabel className={labelClassName} component="label">
          {label}
          {required && '*'}
        </FormLabel>
        {textFieldComponent}
      </>
    )
  }
)

Input.propTypes = {
  fixedDecimalScale: PropTypes.bool,
  InputProps: PropTypes.object,
  autoFocus: PropTypes.bool,
  className: PropTypes.string,
  classes: PropTypes.object,
  decimalScale: PropTypes.number,
  defaultValue: PropTypes.string,
  disabled: PropTypes.bool,
  error: PropTypes.string,
  fullWidth: PropTypes.bool,
  helperText: PropTypes.string,
  label: PropTypes.string,
  labelClassName: PropTypes.string,
  maxNumericValue: PropTypes.number,
  maxLength: PropTypes.number,
  multiline: PropTypes.bool,
  name: PropTypes.string,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  rows: PropTypes.number,
  showPassword: PropTypes.bool,
  small: PropTypes.bool,
  type: PropTypes.oneOf(['default', 'money', 'password', 'textfield', 'number']),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}
Input.defaultProps = {
  fixedDecimalScale: false,
  InputProps: null,
  autoFocus: false,
  className: null,
  classes: null,
  decimalScale: 2,
  defaultValue: undefined,
  disabled: false,
  error: null,
  fullWidth: false,
  helperText: undefined,
  label: '',
  labelClassName: null,
  maxNumericValue: null,
  maxLength: null,
  multiline: false,
  name: null,
  onChange: () => {},
  placeholder: undefined,
  required: false,
  rows: null,
  showPassword: false,
  small: false,
  type: 'default',
  value: undefined,
}

export default Input
