import React from 'react'
import PropTypes from 'prop-types'
import { Box } from '@mui/material'

const Container = ({ children }) => (
  <Box
    sx={theme => ({
      backgroundColor: theme.palette.dualGreen,
      borderBottom: `3px solid ${theme.palette.common.white}`,
      boxShadow: theme.shape.boxShadow,
      display: 'flex',
      height: '106px',
      '@media (min-width: 1024px)': {
        height: '53px',
      },
      left: 0,
      marginLeft: 'auto',
      marginRight: 'auto',
      justifyContent: 'center',
      top: 0,
      width: '100%',
      maxWidth: '1920px',
      position: 'relative',
    })}
  >
    {children}
  </Box>
)
Container.propTypes = {
  children: PropTypes.node.isRequired,
}
export default Container
