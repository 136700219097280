import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Pagination as MPagination } from '@mui/material'

const Pagination = ({ page: propPage, setPage: propSetPage, countPages }) => {
  const [page, setPage] = useState(1)
  useEffect(() => {
    setPage(propPage)
  }, [propPage, setPage])

  const handleChange = (_, newPage) => {
    setPage(newPage)
    propSetPage?.(newPage)
  }
  return (
    <MPagination
      count={countPages}
      variant="outlined"
      shape="rounded"
      page={page}
      onChange={handleChange}
    />
  )
}
Pagination.propTypes = {
  page: PropTypes.number,
  setPage: PropTypes.func,
  countPages: PropTypes.number,
}
Pagination.defaultProps = {
  page: 1,
  setPage: null,
  countPages: 1,
}

export default Pagination
