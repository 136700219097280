import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import logoutImage from '../assets/images/logout-background.jpg'
import { Text } from '../components/atoms'

const useStyles = makeStyles(theme => ({
  container: {
    [theme.breakpoints.up('md')]: {
      paddingRight: theme.spacing(8),
    },
  },
  paddingTop: {
    paddingTop: theme.spacing(4),
  },
  image: {
    paddingTop: theme.spacing(5),
  },
  link: {
    textDecoration: 'none',
    color: theme.palette.secondary.main,
    '&:visited': {
      color: theme.palette.secondary.main,
    },
    '&:hover': {
      color: theme.palette.linkHover,
    },
  },
}))

const AuthDisclaimer = () => {
  const classes = useStyles()
  const { t } = useTranslation('auth')
  return (
    <div>
      <div className={classes.image}>
        <img src={logoutImage} alt="RIGK" width="100%" />
      </div>
      <div className={classes.paddingTop}>
        <Typography variant="h2">{t('logout.title2')}</Typography>
      </div>
      <div className={classes.paddingTop}>
        <Typography variant="h3">{t('logout.title3')}</Typography>
      </div>
      <div className={classes.paddingTop}>
        <Text $color="textGrey">
          <Trans
            t={t}
            i18nKey="auth:logout.content1"
            components={{
              /* eslint-disable jsx-a11y/control-has-associated-label */
              /* eslint-disable jsx-a11y/anchor-has-content */
              l1: (
                <a
                  className={classes.link}
                  href="https://www.rigk.de/ueber-rigk/mission-gesellschafter"
                  target="_blank"
                  rel="noreferrer"
                />
              ),
            }}
          />
        </Text>
      </div>
      <div className={classes.paddingTop}>
        <Text $color="textGrey">
          <Trans
            t={t}
            i18nKey="auth:logout.content2"
            components={{
              l1: (
                <a
                  className={classes.link}
                  href="https://www.rigk.de/nachhaltigkeit/recycling-bei-rigk"
                  target="_blank"
                  rel="noreferrer"
                />
              ),
            }}
            defaults="RIGK wurde"
          />
        </Text>
      </div>
    </div>
  )
}

export default AuthDisclaimer
