import React from 'react'
import PropTypes from 'prop-types'
import { Typography } from '@mui/material'
import { styled } from '@mui/material/styles'
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip'
import { makeStyles } from '@mui/styles'
import clsx from 'clsx'
import { QuestionMarkOutlined } from '../../../assets/icons'
import Text from '../Text'

const StyledTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: 'white',
    boxShadow: '0 6px 16px -8px black',
    maxWidth: 'none',
    padding: theme.spacing(2),
  },
}))

const useStyles = makeStyles(() => ({
  noMaxWitdh: {
    [`& .${tooltipClasses.tooltip}`]: {
      maxWidth: 'none !important',
    },
  },
  tooltip: {
    backgroundColor: 'white',

    boxShadow: '0 6px 16px -8px black',
  },
  marginLeft: {
    marginLeft: 20,
  },
  marginBottom: { marginBottom: 20 },
  titleContent: {
    display: 'flex',
    alignItems: 'center',
  },
  padding: {
    padding: '10px',
  },
}))
const InfoTitle = ({ title, tooltipTitle, tooltipMessage, options }) => {
  const classes = useStyles()
  return (
    <Typography
      sx={theme => ({
        paddingBottom: theme.spacing(2),
      })}
      className={options?.titleClassName}
      variant="h3"
    >
      <div className={classes.titleContent}>
        {title}

        <StyledTooltip
          title={
            tooltipTitle ? (
              <div className={clsx(classes.padding, options?.tooltipBodyClassName)}>
                <Typography
                  variant="h2"
                  color="textSecondary"
                  className={options?.tooltipTitleClassName}
                >
                  {tooltipTitle}
                </Typography>
                <Text fontSize="0.875rem" $color="textGrey" {...options?.tooltipTextOptions}>
                  {tooltipMessage}
                </Text>
              </div>
            ) : null
          }
          className={clsx(classes.noMaxWitdh, classes.whiteBackground, classes.marginLeft)}
          classes={{
            tooltip: classes.tooltip,
          }}
        >
          {tooltipTitle ? <QuestionMarkOutlined height="20px" /> : <></>}
        </StyledTooltip>
      </div>
    </Typography>
  )
}

InfoTitle.propTypes = {
  title: PropTypes.string.isRequired,
  options: PropTypes.shape({
    titleClassName: PropTypes.string,
    tooltipTitleClassName: PropTypes.string,
    tooltipBodyClassName: PropTypes.string,
    tooltipTextOptions: PropTypes.shape({
      $bold: PropTypes.bool,
      $bolder: PropTypes.bool,
      $center: PropTypes.bool,
      $color: PropTypes.string,
      $disabled: PropTypes.bool,
      $italic: PropTypes.bool,
      fontSize: PropTypes.number,
      fontWeight: PropTypes.number,
      lineHeight: PropTypes.number,
    }),
  }),
  tooltipTitle: PropTypes.string,
  tooltipMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
}
InfoTitle.defaultProps = { tooltipTitle: null, options: null }

export default InfoTitle
