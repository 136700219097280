import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import queryString from 'query-string'
import { storeLanguageInLocalStorage } from '../../../utils/helper'
import localeContext from '../../../utils/LocaleContext'
import Select from '../Select'

const LanguagePicker = ({ selectClass, labelClass }) => {
  const { language, setLanguage, availableLanguages } = useContext(localeContext)
  const { location } = window

  const setStoredLanguage = lang => {
    storeLanguageInLocalStorage(lang)
    setLanguage(lang)
    const parsed = queryString.parse(location.search)
    parsed.locale = lang
    const search = queryString.stringify(parsed)

    location.replace(`${location.pathname}?${search}`)
  }
  return (
    <Select
      borderless
      minWidth={0}
      items={availableLanguages}
      value={language.name}
      onChange={setStoredLanguage}
      selectClass={selectClass}
      labelClass={labelClass}
    />
  )
}
LanguagePicker.propTypes = {
  selectClass: PropTypes.string,
  labelClass: PropTypes.string,
}
LanguagePicker.defaultProps = {
  selectClass: undefined,
  labelClass: undefined,
}

export default LanguagePicker
