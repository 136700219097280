import React, { Suspense } from 'react'
import { render } from 'react-dom'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import * as Sentry from '@sentry/browser'
import {
  ExtraErrorData as ExtraErrorDataIntegration,
  HttpClient as HttpClientIntegration,
} from '@sentry/integrations'
import * as SentryReact from '@sentry/react'
import pkg from '../package.json'
import client from './client'
import { CookieBanner } from './components/atoms'
import { GlobalErrorHandler } from './components/molecules'
import config from './config'
import { Layout } from './layouts'
import reportWebVitals from './reportWebVitals'
import Router from './routes/Router'
import routes from './routes/routes'
import theme from './utils/theme'

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [
    new SentryReact.BrowserTracing({
      traceFetch: true,
      traceXHR: true,
      tracePropagationTargets: [/rigk.de/, /localhost/],
    }),
    new Sentry.Replay({
      // Additional SDK configuration goes in here, for example:
      maskAllText: false,
      blockAllMedia: false,
    }),
    new ExtraErrorDataIntegration({
      // Limit of how deep the object serializer should go. Anything deeper than limit will
      // be replaced with standard Node.js REPL notation of [Object], [Array], [Function] or
      // a primitive value. Defaults to 3.
      depth: 3,
    }),
    new HttpClientIntegration(),
  ],
  release: pkg.version,
  tracesSampleRate: 0.4,
  // This sets the sample rate to be 30%. You may want this to be 100% while
  // in development and sample at a lower rate in production
  replaysSessionSampleRate: 0.5,
  // If the entire session is not sampled, use the below sample rate to sample
  // sessions when an error occurs.
  replaysOnErrorSampleRate: 1.0,
  environment: process.env.REACT_APP_ENV,
  tracePropagationTargets: [/rigk.de/, /localhost/],
})

const root = document.getElementById('root')
render(
  <React.StrictMode>
    <SentryReact.ErrorBoundary fallback={null}>
      <BrowserRouter>
        <Routes>
          <Route
            path="*"
            element={
              <Layout
                system="pamira"
                theme={theme}
                client={client}
                config={config}
                routes={routes ?? []}
              >
                <Suspense fallback={null}>
                  <CookieBanner />
                </Suspense>
                <Router routes={routes} />
                <GlobalErrorHandler />
              </Layout>
            }
          />
        </Routes>
      </BrowserRouter>
    </SentryReact.ErrorBoundary>
  </React.StrictMode>,
  root
)
//

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
