/* eslint-disable max-len */
import React from 'react'
import { orderBy } from 'lodash'
import {
  BeizeSystemLogo,
  ErdeRecyclingLogo,
  PamiraSystemLogo,
  PreServiceLogo,
  RigkDualLogo,
  RigkGSystemLogo,
  RigkPickupLogo,
  RigkSystemLogo,
} from '../../../assets/icons'

export const systems = [
  {
    name: 'beize-system',
    color: 'beize',
    title: 'BEIZE-SYSTEM',
    subTitle: 'beizeSubTitle',
    text: 'beizeText',
    imgSrc: <BeizeSystemLogo />,
    overviewText: 'beizeOverviewText',
    href: 'beizeExternalLink',
    pos: 5,
  },

  {
    name: 'erde-recycling',
    color: 'erde',
    title: 'ERDE Recycling',
    subTitle: 'erdeSubTitle',
    text: 'erdeText',
    imgSrc: <ErdeRecyclingLogo />,
    overviewText: 'erdeOverviewText',
    href: 'erdeExternalLink',
    pos: 6,
  },

  {
    name: 'pamira-system',
    color: 'pamiraGreen',
    title: 'PAMIRA-SYSTEM',
    subTitle: 'pamiraSubTitle',
    text: 'pamiraText',
    imgSrc: <PamiraSystemLogo />,
    overviewText: 'pamiraOverviewText',
    href: 'pamiraExternalLink',
    pos: 4,
  },
  {
    name: 'pre-service',
    color: 'pre',
    title: 'PRE-Service',
    subTitle: 'preSubTitle',
    text: 'preText',
    imgSrc: <PreServiceLogo />,
    overviewText: 'preOverviewText',
    href: 'preExternalLink',
    pos: 7,
  },

  {
    name: 'dual',
    color: 'dualGreen',
    title: 'RIGK-DUAL',
    subTitle: 'rigkDualSubTitle',
    text: 'rigkDualText',
    imgSrc: <RigkDualLogo />,
    overviewText: 'rigkDualOverviewText',
    href: 'dualExternalLink',
    pos: 8,
  },
  {
    name: 'rigk-g',
    color: 'gSystemOrange',
    title: 'RIGK-G-SYSTEM',
    subTitle: 'rigkGSystemSubTitle',
    text: 'rigkGSystemText',
    imgSrc: <RigkGSystemLogo />,
    overviewText: 'rigkGSystemOverviewText',
    href: 'rigkgExternalLink',
    pos: 2,
  },
  {
    name: 'pickup',
    color: 'pickupSystemBlue',
    title: 'RIGK-PICKUP',
    subTitle: 'rigkPickupSubTitle',
    text: 'rigkPickupText',
    imgSrc: <RigkPickupLogo />,
    overviewText: 'rigkPickupOverviewText',
    href: 'pickupExternalLink',
    pos: 3,
  },
  {
    name: 'rigk',
    color: 'systemBlue',
    title: 'RIGK-SYSTEM',
    subTitle: 'rigkSystemSubTitle',
    text: 'rigkSystemText',
    imgSrc: <RigkSystemLogo />,
    overviewText: 'rigkSystemOverviewText',
    href: 'rigkExternalLink',
    pos: 1,
  },
]

export const getSystem = data => systems.find(system => system.name === data)
export const getOrderedSystems = sysArray => {
  const items = systems.filter(os => sysArray.includes(os.name.toUpperCase()))
  const orderedItems = orderBy(items, i => i.pos, 'asc')
  return orderedItems.map(oi => oi.name.toUpperCase())
}

export const getReportable = data =>
  orderBy(
    systems?.filter(system =>
      hideDisabledSystems(data?.systems)?.includes(system.name.toUpperCase())
    ),
    s => s.pos,
    'asc'
  )

const disabledSystems = ['DUAL']

export const getNotReportable = data =>
  orderBy(
    systems.filter(
      system => system.name !== 'dual' && !data?.systems?.includes(system.name.toUpperCase())
    ),
    s => s.pos,
    'asc'
  )

export const hideDisabledSystems = sysArray =>
  sysArray?.filter(sys => !disabledSystems.includes(sys))
