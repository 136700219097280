import React from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { makeStyles } from '@mui/styles'
import clsx from 'clsx'
import LogoRIGK from '../../../assets/images/Logo-RIGK.png'
import backgroundImage from '../../../assets/images/topbar-background.jpg'
import { LanguagePicker, Text } from '../../atoms'
import Container from './Container'
import Logo from './Logo'
import LogoText from './LogoText'
import LogoWrapper from './LogoWrapper'
import NavItem from './NavItem'
import StyledA from './StyledA'
import StyledButton from './StyledButton'

const useStyles = makeStyles(theme => ({
  languagepicker: {
    height: '55px',

    '& > .MuiSelect-select': {
      padding: '0px 10px 0 10px !important',
      fontSize: '1.0625rem',
      display: 'flex',
      alignItems: 'center',
      fontWeight: 700,
    },
    '& >.MuiSelect-iconOutlined': {
      right: '0px',
      width: 8,
    },
  },
  label: { padding: 0, margin: 0 },
  row: {
    display: 'flex',
    flexDirection: 'row',
  },
  spaceBetween: {
    maxWidth: theme.containerWidth,
    width: '100%',
    justifyContent: 'space-between',
  },
}))

const TopBar = ({ menuItems }) => {
  const { t } = useTranslation('topbar')
  const { location } = window
  const classes = useStyles()
  const buildMenuItemComponent = item => {
    let returnComponent = (
      <NavItem to={item.to} key={item.title} selected={location?.pathname?.includes(item?.to)}>
        {item.title}
      </NavItem>
    )
    if (item.href) {
      returnComponent = (
        <StyledA
          tabIndex="-1"
          href={item.href}
          key={item.title}
          role="button"
          target="_blank"
          rel="noreferrer"
        >
          {item.title}
        </StyledA>
      )
    }
    if (item.onClick) {
      returnComponent = (
        <StyledButton key={item.title} onClick={item.onClick}>
          {item.title}
        </StyledButton>
      )
    }
    return returnComponent
  }
  return (
    <div>
      <Container>
        <div className={clsx(classes.row, classes.spaceBetween)}>
          <div className={classes.row}>
            <LogoWrapper>
              <Logo src={LogoRIGK} alt="RIGK Logo" />
              <LogoText>
                <Text lineHeight={1.2} fontSize="0.75rem">
                  {t('logoExperienced')}
                </Text>
                <Text lineHeight={1.2} fontSize="0.75rem">
                  {t('logoReliable')}
                </Text>
                <Text lineHeight={1.2} fontSize="0.75rem">
                  {t('logoInnovative')}
                </Text>
              </LogoText>
            </LogoWrapper>
          </div>
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            {menuItems?.map(item => buildMenuItemComponent(item))}

            <LanguagePicker selectClass={classes.languagepicker} labelClass={classes.label} />
          </div>
        </div>
      </Container>
      <img src={backgroundImage} alt="RIGK" width="100%" />
    </div>
  )
}

TopBar.propTypes = {
  menuItems: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.node.isRequired]),
      to: PropTypes.string,
      href: PropTypes.string,
    })
  ).isRequired,
}

export default TopBar
