import React, { Suspense } from 'react'
import { Navigate } from 'react-router-dom'
import PropTypes from 'prop-types'
import { Loading } from '../components/atoms'
import { isLogin } from '../utils/helper'
import TimeOut from '../utils/TimeOut'

const PrivateRoute = ({ component: Component, ...rest }) =>
  isLogin() ? (
    <>
      <Suspense fallback={<Loading />}>
        <TimeOut />
      </Suspense>
      <Suspense fallback={<Loading />}>
        <Component {...rest} />
      </Suspense>
    </>
  ) : (
    <Navigate replace to="/login" />
  )

PrivateRoute.propTypes = {
  component: PropTypes.object.isRequired,
}

export default PrivateRoute
