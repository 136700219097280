import React from 'react'
import { useTranslation } from 'react-i18next'
import { Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import logo from '../../../assets/images/logo.png'

const useStyles = makeStyles(theme => ({
  footer: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'center',
    padding: theme.spacing(4),
    paddingTop: 80,
    '& a': {
      color: theme.palette.secondary.main,
      textDecoration: 'none',
      display: 'flex',
      alignItems: 'center',
      '&:visited': {
        color: theme.palette.secondary.main,
      },
      '&:hover': {
        color: theme.palette.textGrey,
      },
    },
    '& img': {
      marginRight: theme.spacing(1),
    },
  },
  items: {
    width: '100%',
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      '& a': {
        marginBottom: 10,
      },
      '& #imprint': { order: 0 },
      '& #privacy': { order: 1 },
      '& #backToRigkDe': { order: 2, flexDirection: 'column-reverse' },
    },
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
      maxWidth: theme.containerWidth,
      justifyContent: 'space-between',
    },
  },
}))

const Footer = () => {
  const classes = useStyles()

  const { t } = useTranslation('common')
  const linkObj = [
    {
      key: 'imprint',
      title: t('imprint'),
      href: t('imprintLink'),
      img: null,
    },
    {
      key: 'backToRigkDe',
      title: t('backToRigkDe'),
      href: t('backToRigkDeLink'),

      img: { alt: 'RIGK Logo', src: logo },
    },
    {
      key: 'privacy',
      title: t('privacy'),
      href: t('privacyLink'),

      img: null,
    },
  ]

  return (
    <div className={classes.footer}>
      <div className={classes.items}>
        {linkObj.map(link => (
          <a href={link?.href} key={link?.key} id={link?.key} target="_blank" rel="noreferrer">
            {link?.img && <img alt={link.img.alt} src={link.img.src} width={50} />}
            <Typography>{link?.title}</Typography>
          </a>
        ))}
      </div>
    </div>
  )
}

export default Footer
